import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import classes from "./UserOrders.module.css";
import { getUserOrders } from "../../utils/http/orderService";
import { shortenText, translateStatus } from "../../utils/functions";

export default function UserOrders() {
  const isAuthenticated = useSelector((state) => state.auth.isLoggedIn);

  const { data, isError, isLoading, error } = useQuery({
    queryKey: ["userorders"],
    queryFn: getUserOrders,
    staleTime: 5000,
    enabled: isAuthenticated,
  });

  if (isLoading) {
    return <p>Chargement des commandes...</p>;
  }

  if (isError) {
    return <p>Erreur lors du chargement des commandes : {error.message}</p>;
  }

  const calculatePrice = (orderProduct) => {
    const { product, quantity, size } = orderProduct;
    let unitPrice;
    
    switch (size) {
      case 's':
        unitPrice = product.price_small;
        break;
      case 'm':
        unitPrice = product.price_medium;
        break;
      case 'l':
        unitPrice = product.price_large;
        break;
      default:
        unitPrice = product.price_medium;
    }
    
    return (quantity * unitPrice).toFixed(2);
  };

  return (
    <section className={classes.user_orders_container}>
      <TableContainer component={Paper} className={classes.table_container}>
        <Table className={classes.table} sx={{ minWidth: 650 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">Produit</TableCell>
              <TableCell align="center">Prix</TableCell>
              <TableCell align="center">Date de commande</TableCell>
              <TableCell align="center">Statut</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.orders.map((order) =>
              order.order_products.map((orderProduct) => {
                const product = orderProduct.product;
                const firstImage =
                  product.images && product.images.length > 0
                    ? product.images[0].image_url
                    : "https://via.placeholder.com/500?text=No+Image";

                return (
                  <TableRow key={`${order.id}-${product.id}`}>
                    <TableCell align="center">
                      <div className={classes.product_cell}>
                        <div className={classes.product_image_container}>
                          <img
                            src={firstImage}
                            alt={product.name}
                            className={classes.product_image}
                          />
                        </div>
                        <div className={classes.product_infos}>
                          <h4 className={classes.product_name}>
                            <Link
                              style={{ textDecoration: "none" }}
                              to={`/product-details/${product.id}`}
                            >
                              {product.name}
                            </Link>
                          </h4>
                          <p className={classes.product_description}>
                            {shortenText(product.description, 20)}
                          </p>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="center" className={classes.price_cell}>
                      <p className={classes.price}>
                        {calculatePrice(orderProduct)} DH
                      </p>
                    </TableCell>
                    <TableCell align="center" className={classes.price_cell}>
                      <p className={classes.price}>{order.date}</p>
                    </TableCell>
                    <TableCell align="center">
                      <div className={classes.status_cell}>
                        {translateStatus(order.status)}
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </section>
  );
}