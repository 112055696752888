import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addToFavorites,
  fetchFavorites,
  removeFromFavorites,
} from "./../../utils/http/favoritesService";

export const loadFavorites = createAsyncThunk(
  "favorites/loadFavorites",
  async () => {
    const data = await fetchFavorites();
    const favoritesWithProducts = data.favorites
      ? data.favorites.map((favorite) => ({
          ...favorite,
          product: favorite.product,
        }))
      : [];
    return favoritesWithProducts;
  }
);

export const addFavoriteAsync = createAsyncThunk(
  "favorites/addFavorite",
  async (favorite) => {
    const newFavorite = await addToFavorites(favorite);
    return newFavorite;
  }
);

export const removeFavoriteAsync = createAsyncThunk(
  "favorites/removeFavorite",
  async (productId) => {
    await removeFromFavorites(productId);
    return productId;
  }
);

const favoritesSlice = createSlice({
  name: "favorites",
  initialState: [],
  reducers: {
    addFavorite: (state, action) => {
      const exists = state.find((item) => item.product_id === action.payload);
      if (!exists) {
        state.push({ product_id: action.payload });
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadFavorites.fulfilled, (state, action) => {
        return action.payload;
      })
      .addCase(addFavoriteAsync.fulfilled, (state, action) => {
        state.push(action.payload);
      })
      .addCase(removeFavoriteAsync.fulfilled, (state, action) => {
        return state.filter((item) => item.product_id !== action.payload);
      });
  },
});

export default favoritesSlice.reducer;
