import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { setCookie } from "../../utils/functions";
import { CircularProgress, Container, Typography, Box } from "@mui/material";

const GoogleCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");
    const user = queryParams.get("user");

    if (token && user) {
      setCookie("token", token, 7);
      navigate("/");
    } else {
      console.error("Erreur : Token ou données utilisateur manquants.");
      navigate("/login");
    }
  }, [navigate]);

  return (
    <Container maxWidth="sm">
      <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
        <CircularProgress />
        <Typography variant="h6" align="center" mt={2}>
          Authentification en cours, veuillez patienter...
        </Typography>
      </Box>
    </Container>
  );
};

export default GoogleCallback;
