import React, { useState } from "react";
import { Form, Link, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { Alert, Box, Drawer, ListItem } from "@mui/material";
import { submitRegisterForm } from "../../utils/http/registerService";
import {
  validateEmail,
  validatePassword,
  validateName,
  validatePasswordMatch,
} from "../../utils/validation";
import classes from "./MobileAuthForm.module.css";
import Close from "./../../assets/icons/close.svg";
import AuthGoogle from "./../../assets/icons/auth_google.svg";
import AuthFacebook from "./../../assets/icons/auth_facebook.svg";
import CFacebook from "./../../assets/icons/auth/circledFacebook.svg";
import CInstagram from "./../../assets/icons/auth/circledInstagram.svg";
import CTiktok from "./../../assets/icons/auth/circledTikTok.svg";
import CWhatsapp from "./../../assets/icons/auth/CircledWhatsapp.svg";

export default function MobileRegisterForm({ open }) {
  const handleGoogleLogin = () => {
    window.location.href = "https://crm.leschandelles.ma/api/auth/google";
  };

  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordConfirmationError, setPasswordConfirmationError] =
    useState("");

  const { mutate, isPending, isError, isSuccess, error } = useMutation({
    mutationFn: submitRegisterForm,
    onSuccess: (data) => {
      console.log("User registered successfully:", data);
    },
    onError: (error) => {
      console.error("Registration failed:", error.message);
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    const usernameValidationError = validateName(username);
    if (usernameValidationError) {
      setUsernameError(usernameValidationError);
    } else {
      setUsernameError("");
    }

    const emailValidationError = validateEmail(email);
    if (emailValidationError) {
      setEmailError(emailValidationError);
    } else {
      setEmailError("");
    }

    const passwordValidationError = validatePassword(password);
    if (passwordValidationError) {
      setPasswordError(passwordValidationError);
    } else {
      setPasswordError("");
    }

    const passwordMatchError = validatePasswordMatch(
      password,
      passwordConfirmation
    );
    if (passwordMatchError) {
      setPasswordConfirmationError(passwordMatchError);
    } else {
      setPasswordConfirmationError("");
    }

    if (
      !usernameValidationError &&
      !emailValidationError &&
      !passwordValidationError &&
      !passwordMatchError
    ) {
      const formData = {
        name: username,
        email,
        password,
        password_confirmation: passwordConfirmation,
      };
      console.log("Form data:", formData);
      mutate(formData);
    }
  };

  const handleClose = () => {
    navigate("/");
  };

  return (
    <Drawer open={open} onClose={handleClose} anchor="left">
      <Box
        sx={{
          width: "100vw",
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ListItem disablePadding>
          <div
            className={classes.close_container}
            onClick={handleClose}
            aria-label="Close"
          >
            <div>
              <img src={Close} alt="Close" className={classes.close_icon} />
            </div>
            <div className={classes.close_text}>FERMER</div>
          </div>
        </ListItem>
        <div className={classes.form_container}>
          <h1 className={classes.title}>Créer un compte</h1>
          <p className={classes.redirect}>
            Vous avez déjà un compte ?{" "}
            <Link className={classes.redirect_link} to="/login">
              Se connecter
            </Link>
          </p>
          {isError && (
            <Alert severity="error">
              {error.message || "Une erreur est survenue. Veuillez réessayer."}
            </Alert>
          )}
          {isSuccess && (
            <Alert severity="success">
              Votre compte a été créé. Veuillez vous connecter.
            </Alert>
          )}
          <br />
          <Form onSubmit={handleSubmit}>
            <div className={classes.form_group}>
              <input
                type="text"
                name="username"
                placeholder="Entrez votre Prénom et nom"
                className={usernameError ? classes.input_error : classes.input}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              {usernameError && (
                <span className={classes.error_message}>{usernameError}</span>
              )}
            </div>
            <div className={classes.form_group}>
              <input
                type="email"
                name="email"
                placeholder="Entrez votre adresse e-mail"
                className={emailError ? classes.input_error : classes.input}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {emailError && (
                <span className={classes.error_message}>{emailError}</span>
              )}
            </div>
            <div className={classes.form_group}>
              <input
                type="password"
                name="password"
                placeholder="Entrez votre mot de passe."
                className={passwordError ? classes.input_error : classes.input}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {passwordError && (
                <span className={classes.error_message}>{passwordError}</span>
              )}
            </div>
            <div className={classes.form_group}>
              <input
                type="password"
                name="password_confirmation"
                placeholder="Confirmer votre mot de passe."
                className={
                  passwordConfirmationError
                    ? classes.input_error
                    : classes.input
                }
                value={passwordConfirmation}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
              />
              {passwordConfirmationError && (
                <span className={classes.error_message}>
                  {passwordConfirmationError}
                </span>
              )}
            </div>
            <div className={classes.form_group}>
              <p className={classes.checkbox_container}>
                <input
                  className={classes.checkbox}
                  type="checkbox"
                  name="remember"
                  id="remember_me"
                />
                <label htmlFor="remember_me">Se souvenir de moi</label>
              </p>
            </div>
            <div>
              <button type="submit" className={classes.submit_button}>
                {isPending ? "Enregistrement en cours..." : "S'inscrire"}
              </button>
            </div>
            <p className={classes.use_other_method}>
              Ou utiliser d'autres méthodes.
            </p>
            <div
              className={classes.social_login}
              onClick={handleGoogleLogin}
              style={{ cursor: "pointer" }}
            >
              <img src={AuthGoogle} alt="Google" />
              <p>Continuer avec Google</p>
            </div>
            {false && (
              <div className={classes.social_login}>
                <img src={AuthFacebook} alt="Facebook" />
                <p>Continuer avec Facebook</p>
              </div>
            )}
          </Form>
          <div className={classes.slogan_container}>
            <h1 className={classes.slogan}>Crafting light, Sharing delight</h1>
          </div>
          <div className={classes.social_links}>
            <Link
              to="https://facebook.com/people/les-chandelles/61553683991616/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={CFacebook} alt="Facebook" />
            </Link>
            <Link
              to="https://www.instagram.com/les_chandelles_/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={CInstagram} alt="Instagram" />
            </Link>
            <Link
              to="https://www.tiktok.com/@les_chandelles_"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={CTiktok} alt="TikTok" />
            </Link>
            <Link
              to="https://wa.me/+212637222323"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={CWhatsapp} alt="WhatsApp" />
            </Link>
          </div>
        </div>
      </Box>
    </Drawer>
  );
}
