import axios from "axios";
import { ApiRoutes } from "../../apis/ApiRoutes";

const apiClient = axios.create({
  baseURL: ApiRoutes.baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const fetchPerfumes = async () => {
  try {
    const response = await apiClient.get(ApiRoutes.perfumes());
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(
        error.response.data.message ||
          "Erreur lors de la récupération des parfums"
      );
    } else if (error.request) {
      throw new Error("Aucune réponse du serveur");
    } else {
      throw new Error("Erreur dans la configuration de la requête");
    }
  }
};
