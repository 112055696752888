import React, { useState, useEffect, useRef, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams, Link } from "react-router-dom";
import ArrowDownwardSharpIcon from "@mui/icons-material/ArrowDownwardSharp";
import { Grid, Box, CircularProgress, Alert } from "@mui/material"; // Import Alert
import classes from "./NewArrivals.module.css";
import { calculateTimeRemaining, shortenText } from "../../utils/functions";
import { fetchCollections } from "../../utils/http/collectionService";

function NewArrivals() {
  const { name } = useParams();
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["collections"],
    queryFn: fetchCollections,
    staleTime: 5000,
    select: (data) => data.collections || [],
  });

  const CollectionData = useMemo(() => {
    return data ? data.filter((collection) => collection.name === name) : [];
  }, [data, name]);

  const newArrivalsData = CollectionData[0]?.new_arrivals[0]?.products || [];
  const [timeRemaining, setTimeRemaining] = useState(null);
  const scrollRef = useRef(null);

  const handleScroll = () => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const endDate = CollectionData[0]?.new_arrivals[0]?.end_date;

    if (endDate) {
      const newTimeRemaining = calculateTimeRemaining(endDate);
      setTimeRemaining((prev) => {
        if (JSON.stringify(prev) !== JSON.stringify(newTimeRemaining)) {
          return newTimeRemaining;
        }
        return prev;
      });
    }
  }, [CollectionData]);

  useEffect(() => {
    const endDate = CollectionData[0]?.new_arrivals[0]?.end_date;
    const intervalId = setInterval(() => {
      if (endDate) {
        const newTimeRemaining = calculateTimeRemaining(endDate);
        setTimeRemaining((prev) => {
          if (JSON.stringify(prev) !== JSON.stringify(newTimeRemaining)) {
            return newTimeRemaining;
          }
          return prev;
        });
      }
    }, 60000);

    return () => clearInterval(intervalId);
  }, [CollectionData]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="50vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <section className={classes.new_arrivals_container}>
      <div className={classes.button_container}>
        {data.map((collection) => (
          <Link
            to={`/new-arrivals/${collection.name}`}
            key={collection.id}
            className={`${classes.category_btn} ${
              name === collection.name ? classes.active : ""
            }`}
          >
            {collection.name}
          </Link>
        ))}
      </div>
      <div className={classes.titles_container}>
        <h1>Obtenez jusqu'à 50 % de réduction</h1>
        <h4>Nouvelles Arrivées</h4>
      </div>
      {CollectionData.length > 0 && newArrivalsData.length > 0 ? (
        <>
          <Grid container spacing={0} className={`${classes.image_container}`}>
            <Grid item xs={12}>
              <Box className={classes.collection_image_box}>
                <img
                  src={CollectionData[0].image}
                  alt={CollectionData[0].name}
                />
                <div className={classes.overlay}>
                  <div className={classes.overlay_time_content}>
                    {timeRemaining && (
                      <div className={classes.time_remaining}>
                        <div className={classes.time_container}>
                          <h2>{timeRemaining.days}</h2>
                          <span>Days</span>
                        </div>
                        <div className={classes.time_container}>
                          <h2>{timeRemaining.hours}</h2>
                          <span>Hours</span>
                        </div>
                        <div className={classes.time_container}>
                          <h2>{timeRemaining.minutes}</h2>
                          <span>Minutes</span>
                        </div>
                      </div>
                    )}
                    <div className={classes.order_container}>
                      Passez votre commande
                    </div>
                    {timeRemaining && (
                      <button
                        className={classes.order_btn}
                        onClick={handleScroll}
                      >
                        <ArrowDownwardSharpIcon
                          className={classes.order_btn_icon}
                        />
                      </button>
                    )}
                  </div>
                  <p className={classes.left_bottom_text}>
                    {CollectionData[0].new_arrivals[0]?.tag}
                  </p>
                </div>
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={0}
            className={`${classes.image_container}`}
            ref={scrollRef}
          >
            {newArrivalsData.map((item, index) => {
              if (index % 2 === 0 && newArrivalsData[index + 1]) {
                const isReversed = (index / 2) % 2 === 1;
                return (
                  <Grid container spacing={0} key={index}>
                    <Grid
                      item
                      xs={isReversed ? 4 : 8}
                      className={
                        isReversed ? classes.square_box : classes.rectangle_box
                      }
                    >
                      <Box
                        className={`${classes.image_box} ${classes.square_box}`}
                      >
                        <img src={item.images[0].image_url} alt={item.name} />
                        <div className={classes.overlay}>
                          <div className={classes.overlay_content}>
                            <h1>{item.name}</h1>
                            <div className={classes.description}>
                              <p>{shortenText(item.description, 30)}</p>
                            </div>
                            <div className={classes.promotion}>
                              <span className={classes.promotion_value}>
                                -{item.promotion}%
                              </span>
                              <span className={classes.promotion_off}>OFF</span>
                            </div>
                          </div>
                        </div>
                      </Box>
                    </Grid>
                    <Grid item xs={isReversed ? 8 : 4}>
                      <Box
                        className={`${classes.image_box} ${classes.rectangle_box}`}
                      >
                        <img
                          src={newArrivalsData[index + 1].images[0].image_url}
                          alt={newArrivalsData[index + 1].name}
                        />
                        <div className={classes.overlay}>
                          <div className={classes.overlay_content}>
                            <h1>{newArrivalsData[index + 1].name}</h1>
                            <div className={classes.description}>
                              <p>
                                {shortenText(
                                  newArrivalsData[index + 1].description,
                                  30
                                )}
                              </p>
                            </div>
                            <div className={classes.promotion}>
                              <span className={classes.promotion_value}>
                                -{newArrivalsData[index + 1].promotion}%
                              </span>
                              <span className={classes.promotion_off}>OFF</span>
                            </div>
                          </div>
                        </div>
                      </Box>
                    </Grid>
                  </Grid>
                );
              }
              return null;
            })}
          </Grid>
        </>
      ) : (
        <Alert severity="info">
          Aucune nouvelle arrivée disponible pour cette collection.
        </Alert>
      )}
    </section>
  );
}

export default NewArrivals;
