export const calculateTimeRemaining = (endDate) => {
  const now = new Date();
  const end = new Date(endDate);
  const total = end - now;
  if (total <= 0) return { days: 0, hours: 0, minutes: 0 };
  const days = Math.floor(total / (1000 * 60 * 60 * 24));
  const hours = Math.floor((total % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((total % (1000 * 60 * 60)) / (1000 * 60));
  return { days, hours, minutes };
};
