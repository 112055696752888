import React from "react";
import Favorites from "../components/favorites/Favorites";

function FavoritesPage() {
  return (
    <>
      <Favorites />
    </>
  );
}

export default FavoritesPage;
