import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Alert, Box, CircularProgress, Grid } from "@mui/material";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import { getUser } from "../../utils/http/userService";
import UserInfoForm from "./UserInfoForm";
import ChangePasswordForm from "./ChangePasswordForm";
import UserOrders from "./UserOrders";
import classes from "./Profile.module.css";

const Profile = () => {
  
  const navigate = useNavigate();

  const auth = useSelector((state) => state.auth.isLoggedIn);

  useEffect(() => {
    if (!auth) {
      navigate("/login");
    }
  }, [auth, navigate]);

  const {
    data: user,
    isError,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["user"],
    queryFn: getUser,
    staleTime: 5000,
    enabled: auth,
  });

  const [expanded, setExpanded] = useState({
    panel1: true,
    panel2: true,
    panel3: true,
  });

  const [activePanel, setActivePanel] = useState("");

  const panel1Ref = useRef(null);
  const panel2Ref = useRef(null);
  const panel3Ref = useRef(null);

  const handleScroll = (ref) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  if (user) {
    console.log(user);
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [panel]: isExpanded,
    }));
  };

  if (isLoading || !user) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="30vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="30vh"
      >
        <Alert severity="error" style={{ minWidth: "50vw" }}>
          {error.message || "Erreur lors de la récupération des données."}
        </Alert>
      </Box>
    );
  }

  return (
    <section className={classes.profile_container}>
      <div className={classes.scrolling_nav}>
        <ul>
          <li
            className={
              activePanel === "panel1"
                ? classes.scrolling_item_active
                : classes.scrolling_item
            }
            onClick={() => {
              setActivePanel("panel1");
              handleScroll(panel1Ref);
            }}
          >
            <div
              className={classes.scrolling_item}
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <ManageAccountsOutlinedIcon className={classes.icon} />
              <span>Mon Compte</span>
            </div>
          </li>
          {user && user.data.google_id !== null ? null : (
            <li
              className={
                activePanel === "panel2"
                  ? classes.scrolling_item_active
                  : classes.scrolling_item
              }
              onClick={() => {
                setActivePanel("panel2");
                handleScroll(panel2Ref);
              }}
            >
              <div
                className={classes.scrolling_item}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <GppGoodOutlinedIcon className={classes.icon} />
                <span>Sécurité du compte</span>
              </div>
            </li>
          )}
          <li
            className={
              activePanel === "panel3"
                ? classes.scrolling_item_active
                : classes.scrolling_item
            }
            onClick={() => {
              setActivePanel("panel3");
              handleScroll(panel3Ref);
            }}
          >
            <div
              className={classes.scrolling_item}
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <ShoppingBagOutlinedIcon className={classes.icon} />
              <span>Mes commandes</span>
            </div>
          </li>
        </ul>
      </div>
      <Grid container spacing={2} className={classes.profile_grid}>
        <Grid item xs={12}>
          <Accordion
            ref={panel1Ref}
            expanded={expanded.panel1}
            onChange={handleChange("panel1")}
            className={classes.accordion}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              className={`${classes.accordion_summary} ${
                expanded.panel1
                  ? classes.accordion_summary_open
                  : classes.accordion_summary_closed
              }`}
            >
              <Typography className={classes.accordion_title}>
                Mon compte
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordion_details}>
              {user && user.data ? (
                <UserInfoForm user={user.data} />
              ) : (
                <CircularProgress />
              )}
            </AccordionDetails>
          </Accordion>

          {user && user.data.google_id !== null ? null : (
            <Accordion
              ref={panel2Ref}
              expanded={expanded.panel2}
              onChange={handleChange("panel2")}
              className={classes.accordion}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                className={`${classes.accordion_summary} ${
                  expanded.panel2
                    ? classes.accordion_summary_open
                    : classes.accordion_summary_closed
                }`}
              >
                <Typography className={classes.accordion_title}>
                  Sécurité du compte
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordion_details}>
                <ChangePasswordForm />
              </AccordionDetails>
            </Accordion>
          )}
          <Accordion
            ref={panel3Ref}
            expanded={expanded.panel3}
            onChange={handleChange("panel3")}
            className={classes.accordion}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
              className={`${classes.accordion_summary} ${
                expanded.panel3
                  ? classes.accordion_summary_open
                  : classes.accordion_summary_closed
              }`}
            >
              <Typography className={classes.accordion_title}>
                Mes Commandes
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordion_details}>
              <UserOrders />
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </section>
  );

};

export default Profile;
