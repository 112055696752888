import React from "react";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import {
  Box,
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  CircularProgress,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useTheme, useMediaQuery } from "@mui/material";
import Close from "./../../../assets/icons/close.svg";
import Facebook from "./../../../assets/icons/facebook.svg";
import Instagram from "./../../../assets/icons/instagram.svg";
import TikTok from "./../../../assets/icons/tiktok.svg";
import WhatsApp from "./../../../assets/icons/whatsapp.svg";
import RightArrow from "./../../../assets/icons/rightArrow.svg";
import classes from "./TemporaryDrawer.module.css";
import { fetchCollections } from "../../../utils/http/collectionService";

export default function TemporaryDrawer({ open, onClose }) {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const drawerWidth = isSmallScreen ? "100vw" : "30vw";

  const handleLinkClick = () => {
    onClose();
  };

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["collections"],
    queryFn: fetchCollections,
    staleTime: 5000,
    select: (data) => data.collections || [],
  });

  const DrawerListContent = () => {
    if (isLoading) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="50vh"
        >
          <CircularProgress />
        </Box>
      );
    }

    if (isError) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="50vh"
        >
          <Typography variant="body1" color="error">
            {error.message || "Erreur lors de la récupération des collections."}
          </Typography>
        </Box>
      );
    }

    return (
      <>
        <List>
          <ListItem disablePadding>
            <div className={classes.close_container} onClick={onClose}>
              <ListItemIcon>
                <img src={Close} alt="Close" className={classes.close_icon} />
              </ListItemIcon>
              <div className={classes.list_item_text}>Close</div>
            </div>
          </ListItem>
          {data.map((collection) => (
            <Link
              to={`new-arrivals/${collection.name}`}
              className={classes.nav_link}
              key={collection.id}
              onClick={handleLinkClick}
            >
              <ListItem disablePadding>
                <ListItemButton className={classes.list_item_button}>
                  <div className={classes.list_item_text}>
                    {collection.name}
                  </div>
                  <div className={classes.list_item_icon}>
                    <img src={RightArrow} alt="Right Arrow" />
                  </div>
                </ListItemButton>
              </ListItem>
            </Link>
          ))}
        </List>
        <div className={classes.divider_container}>
          <Divider className={classes.divider} />
        </div>
        <List>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                handleLinkClick();
                navigate("/about");
              }}
            >
              <div className={classes.list_item_text}>À propos</div>
              <div className={classes.list_item_icon}>
                <img src={RightArrow} alt="Right Arrow" />
              </div>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                handleLinkClick();
                navigate("/contact");
              }}
            >
              <div className={classes.list_item_text}>Contact</div>
              <div className={classes.list_item_icon}>
                <img src={RightArrow} alt="Right Arrow" />
              </div>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                handleLinkClick();
                navigate("/faq");
              }}
            >
              <div className={classes.list_item_text}>F&Q</div>
              <div className={classes.list_item_icon}>
                <img src={RightArrow} alt="Right Arrow" />
              </div>
            </ListItemButton>
          </ListItem>
        </List>
      </>
    );
  };

  const DrawerList = (
    <Box
      sx={{ width: drawerWidth }}
      role="presentation"
      onClick={(event) => event.stopPropagation()}
    >
      <div className={classes.drawer_content}>
        <DrawerListContent />
        <div className={classes.social_links}>
          <Link
            to="https://facebook.com/people/les-chandelles/61553683991616/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Facebook}
              alt="Facebook"
              className={classes.social_link}
            />
          </Link>
          <Link
            to="https://www.instagram.com/les_chandelles_/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Instagram}
              alt="Instagram"
              className={classes.social_link}
            />
          </Link>
          <Link
            to="https://www.tiktok.com/@les_chandelles_"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={TikTok} alt="TikTok" className={classes.social_link} />
          </Link>
          <Link
            to="https://wa.me/+212637222323"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={WhatsApp}
              alt="WhatsApp"
              className={classes.social_link}
            />
          </Link>
        </div>
        <div className={classes.divider_container}>
          <Divider className={classes.divider} />
        </div>
        <div className={classes.navbar_footer}>
          <Link
            to={isLoggedIn ? "/profile" : "/login"}
            className={classes.nav_link}
            onClick={handleLinkClick}
          >
            {isLoggedIn ? "Mon Profil" : "Connexion"}
          </Link>

        </div>
      </div>
     
    </Box>
  );

  return (
    <Drawer
      open={open}
      onClose={onClose}
      sx={{ width: drawerWidth, flexShrink: 0 }}
    >
      {DrawerList}
    </Drawer>
  );
}
