import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import AuthImage from "../components/auth/AuthImage";
import LoginForm from "../components/auth/LoginForm";
import MobileLoginForm from "../components/auth/MobileLoginForm";

function LoginPage() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:768px)");

  const auth = useSelector((state) => state.auth.isLoggedIn);
  useEffect(() => {
    if (auth) {
      navigate("/");
    }
  }, [auth, navigate]);

  return (
    <>
      {!isMobile ? (
        <section style={{ display: "flex" }}>
          <AuthImage />
          <LoginForm />
        </section>
      ) : (
        <MobileLoginForm open />
      )}
    </>
  );
}

export default LoginPage;
