import axios from "axios";
import { ApiRoutes } from "../../apis/ApiRoutes";
import { getCookie, setCookie } from "../functions";

const createApiClient = () => {
  const token = getCookie("token");
  return axios.create({
    baseURL: ApiRoutes.baseURL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const userLogout = async () => {
  const apiClient = createApiClient();
  try {
    const response = await apiClient.post(ApiRoutes.logout());
    setCookie("token", "", -1);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(
        error.response.data.message || "Erreur lors de la déconnexion"
      );
    } else if (error.request) {
      throw new Error(
        "Aucune réponse du serveur. Veuillez réessayer plus tard."
      );
    } else {
      throw new Error("Erreur dans la configuration de la requête.");
    }
  }
};
