import React, { useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import classes from "./FilterMenu.module.css";
import SelectArrow from "./../../assets/icons/SelectArrow.svg";

function FilterMenu({ label, items, onSelect, selectedItem, borderRight, borderLeft }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (item) => {
    onSelect(item);
    setAnchorEl(null);
  };

  return (
    <div
      className={`${classes.filter_menu_wrapper} ${borderRight ? classes.border_right : ''} ${borderLeft ? classes.border_left : ''}`}
    >
      <div
        className={classes.select_wrapper}
        aria-controls="filter-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <p>{selectedItem ? selectedItem : label}</p>
        <div className={classes.select_arrow_container}>
          <img
            src={SelectArrow}
            className={classes.select_arrow_icon}
            alt="Select Arrow"
          />
        </div>
      </div>
      <Menu
        id="filter-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => handleClose(selectedItem)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        PaperProps={{
          style: {
            maxHeight: "200px",
            width: anchorEl ? anchorEl.clientWidth : undefined,
          },
        }}
      >
        {items.map((item, index) => (
          <MenuItem
            key={index}
            selected={item === selectedItem}
            onClick={() => handleClose(item)}
          >
            {item}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default FilterMenu;
