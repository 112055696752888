import React from "react";
import ContactImage from "./../../assets/images/contact/contact-us.png";
import LeschandelleLogo from "./../../assets/images/contact/leschandeles-white-logo.png";
import classes from "./ContactImg.module.css";

function ContactImg() {
  return (
    <div className={classes.contact_img_container}>
      <h2 className={classes.contact_img_title}>Welcome To</h2>
      <img
        className={classes.contact_img_logo}
        src={LeschandelleLogo}
        alt="Leschandelle logo"
      />
      <img
        className={classes.contact_img_img}
        src={ContactImage}
        alt="Contact"
      />
      <div className={classes.circle1}></div>
      <div className={classes.circle2}></div>
      <div className={classes.circle3}></div>
    </div>
  );
}

export default ContactImg;
