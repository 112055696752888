/**
 * Validates the provided email.
 * @param {string} email - The email to validate.
 * @returns {string | null} - An error message if the email is invalid, otherwise `null`.
 */
export function validateEmail(email) 
{
    if (!email.trim()) {
        return 'L\'email ne peut pas être vide.';
    }
  
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
        return 'L\'email n\'est pas valide.';
    }
  
    return null;
  }
  