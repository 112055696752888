/**
 * Validates the provided name.
 * @param {string} name - The name to validate.
 * @returns {string | null} - An error message if the name is invalid, otherwise `null`.
 */
export function validateName(name) {
  if (!name.trim()) {
    return "Le nom ne peut pas être vide.";
  }

  const nameRegex = /^[A-Za-z\s]+$/;
  if (!nameRegex.test(name)) {
    return "Le nom ne peut contenir que des lettres et des espaces.";
  }

  return null;
}
