import React from "react";
import { Outlet } from "react-router-dom";
import Infobar from "./../components/common/Infobar/Infobar";
import Navbar from "../components/common/navbar/Navbar";
import Footer from "../components/common/footer/Footer";

function PublicLayout() {
  return (
    <>
      <Infobar />
      <Navbar />
      <Outlet />
      <Footer />
    </>
  );
}

export default PublicLayout;
