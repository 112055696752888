import React from 'react';
import BgImg from "./../../../assets/images/about/about-hero.jpeg";
import classes from "./AboutHero.module.css";

function AboutHero() {
  return (
    <div className={classes.about_hero_container}>
      <img src={BgImg} alt="" className={classes.about_hero_bg} />
    </div>
  )
}

export default AboutHero;
