/**
 * Validates that the password and confirm password match.
 * @param {string} password - The password to validate.
 * @param {string} confirmPassword - The password confirmation to validate.
 * @returns {string | null} - An error message if the passwords do not match, otherwise `null`.
 */
export function validatePasswordMatch(password, confirmPassword) {
    
  if (password !== confirmPassword) {
    return "Les mots de passe ne correspondent pas.";
  }

  return null;
}
