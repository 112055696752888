import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid } from "@mui/material";
import classes from "./FrequentlyAskedQuestions.module.css";
import FaqImg from "./../../assets/images/faq/image-1.png";
import Title from "./../ui/Title";

const ControlledAccordions = ({ faqs }) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.accordion_container}>
      {faqs.map((item) => (
        <Accordion
          key={item.id}
          expanded={expanded === item.id}
          onChange={handleChange(item.id)}
          className={classes.accordion}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${item.id}-content`}
            id={`${item.id}-header`}
            className={classes.accordion_summary}
          >
            <Typography
              className={`${classes.questions} ${
                expanded === item.id ? classes.expanded : ""
              }`}
            >
              {item.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordion_details}>
            <Typography className={classes.answer}>{item.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

const AnswerToQuestion = ({ faqs }) => {
  return (
    <section style={{ marginTop: "75px" }}>
      <Title title="RÉPONSE À CHAQUE QUESTION" />
      <div className={classes.faq_container}>
        <Grid container spacing={2} className={classes.faqs}>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ControlledAccordions faqs={faqs} />
          </Grid>
          <Grid item xs={12} md={6} className={classes.faq_image_container}>
            <img className={classes.faq_image} src={FaqImg} alt="Faq" />
          </Grid>
        </Grid>
      </div>
    </section>
  );
};

export default AnswerToQuestion;
