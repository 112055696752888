import React, { Fragment } from "react";
import Hero from "../components/home/hero/Hero";
import BestSellers from "../components/home/best-sellers/BestSellers";
import Slogan from "../components/ui/Slogan";
import OurPerfumes from "../components/home/our-perfumes/OurPerfumes";
import SubscribeNewsletter from "../components/common/newsletter/SubscribeNewsletter";

function HomePage() {
  return (
    <Fragment>
      <Hero />
      <BestSellers />
      <Slogan text="Crafting light, Sharing delight"/>
      <OurPerfumes />
      <SubscribeNewsletter />
    </Fragment>
  );
}

export default HomePage;
