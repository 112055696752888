import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { Alert, useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import FavoriteBorderSharpIcon from "@mui/icons-material/FavoriteBorderSharp";
import FavoriteSharpIcon from "@mui/icons-material/FavoriteSharp";
import CircularProgress from "@mui/material/CircularProgress";
import { fetchBestSellers } from "../../../utils/http/productService";
import {
  addFavoriteAsync,
  loadFavorites,
  removeFavoriteAsync,
} from "../../../redux/slices/favoritesSlice";
import Title from "./../../ui/Title";
import Bag from "./../../../assets/icons/Shopping Bag.svg";
import classes from "./BestSellers.module.css";

const BestSellers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const favorites = useSelector((state) => state.favorites);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["bestsellers"],
    queryFn: fetchBestSellers,
    staleTime: 5000,
    select: (data) => {
      return data.products || [];
    },
  });
  
  const [loadingStates, setLoadingStates] = useState({});
  const isFavorite = (productId) =>
    favorites.some((item) => item.product_id === productId);

  const handleFavoriteToggle = async (productId) => {
    if (isLoggedIn) {
      setLoadingStates((prev) => ({ ...prev, [productId]: true }));
      try {
        if (isFavorite(productId)) {
          await dispatch(removeFavoriteAsync(productId));
        } else {
          await dispatch(addFavoriteAsync(productId));
        }
        await dispatch(loadFavorites());
      } finally {
        setLoadingStates((prev) => ({ ...prev, [productId]: false }));
      }
    } else {
      navigate("/login");
    }
  };

  const handleAddToCart = (id) => {
    navigate(`/product-details/${id}`);
  };

  const getClassForItem = (index) => {
    return index % 3 === 0 ? classes["double-width"] : classes["single-width"];
  };

  const renderFavoriteIcon = (productId) => {
    if (loadingStates[productId]) {
      return <CircularProgress size={24} />;
    } else if (isFavorite(productId)) {
      return <FavoriteSharpIcon style={{ color: "red" }} />;
    } else {
      return <FavoriteBorderSharpIcon />;
    }
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="30vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="30vh"
      >
        <Alert severity="error" style={{ minWidth: "50vw" }}>
          {error.message || "Erreur lors de la récupération des collections."}
        </Alert>
      </Box>
    );
  }

  return (
    <Box className={classes.best_sellers_container}>
      <Title title="MEILLEURES VENTES" />
      {isSmallScreen ? (
        <div className={classes.container}>
          <div className={classes.top_container}>
            <div
              className={`${classes.vertical_image} ${classes.image}`}
              style={{
                backgroundImage: `url(${data[1]?.images[0]?.image_url})`,
              }}
            >
              <div
                className={classes.overlay}
                onClick={() => handleFavoriteToggle(data[1]?.id)}
              >
                {renderFavoriteIcon(data[1]?.id)}
              </div>
              <div className={classes.cart_icon}>
                <img
                  src={Bag}
                  alt="Shopping Bag"
                  onClick={() => handleAddToCart(data[1]?.id)}
                />
              </div>
            </div>
            <div className={classes.right_container}>
              <div
                className={`${classes.horizontal_image_top} ${classes.image}`}
                style={{
                  backgroundImage: `url(${data[0]?.images[0]?.image_url})`,
                }}
              >
                <div
                  className={classes.overlay}
                  onClick={() => handleFavoriteToggle(data[0]?.id)}
                >
                  {renderFavoriteIcon(data[0]?.id)}
                </div>
                <div className={classes.cart_icon}>
                  <img
                    src={Bag}
                    alt="Shopping Bag"
                    onClick={() => handleAddToCart(data[0]?.id)}
                  />
                </div>
              </div>
              <div
                className={`${classes.horizontal_image_bottom} ${classes.image}`}
                style={{
                  backgroundImage: `url(${data[2]?.images[0]?.image_url})`,
                }}
              >
                <div
                  className={classes.overlay}
                  onClick={() => handleFavoriteToggle(data[2]?.id)}
                >
                  {renderFavoriteIcon(data[2]?.id)}
                </div>
                <div className={classes.cart_icon}>
                  <img
                    src={Bag}
                    alt="Shopping Bag"
                    onClick={() => handleAddToCart(data[2]?.id)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={`${classes.full_width_image} ${classes.image}`}
            style={{ backgroundImage: `url(${data[3]?.images[0]?.image_url})` }}
          >
            <div
              className={classes.overlay}
              onClick={() => handleFavoriteToggle(data[3]?.id)}
            >
              {renderFavoriteIcon(data[3]?.id)}
            </div>
            <div className={classes.cart_icon}>
              <img
                src={Bag}
                alt="Shopping Bag"
                onClick={() => handleAddToCart(data[3]?.id)}
              />
            </div>
          </div>
          <div className={classes.bottom_container}>
            <div
              className={`${classes.half_width_image} ${classes.image}`}
              style={{
                backgroundImage: `url(${data[4]?.images[0]?.image_url})`,
              }}
            >
              <div
                className={classes.overlay}
                onClick={() => handleFavoriteToggle(data[4]?.id)}
              >
                {renderFavoriteIcon(data[4]?.id)}
              </div>
              <div className={classes.cart_icon}>
                <img
                  src={Bag}
                  alt="Shopping Bag"
                  onClick={() => handleAddToCart(data[4]?.id)}
                />
              </div>
            </div>
            <div
              className={`${classes.half_width_image} ${classes.image}`}
              style={{
                backgroundImage: `url(${data[5]?.images[0]?.image_url})`,
              }}
            >
              <div
                className={classes.overlay}
                onClick={() => handleFavoriteToggle(data[5]?.id)}
              >
                {renderFavoriteIcon(data[5]?.id)}
              </div>
              <div className={classes.cart_icon}>
                <img
                  src={Bag}
                  alt="Shopping Bag"
                  onClick={() => handleAddToCart(data[5]?.id)}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        [data.slice(0, 3), data.slice(3, 6)].map((row, rowIndex) => (
          <div
            key={rowIndex}
            className={`${classes.container} ${
              rowIndex % 2 !== 0 ? classes.reverse : ""
            }`}
          >
            {row.map((item, index) => (
              <div
                key={item.id}
                className={getClassForItem(index)}
                style={{ backgroundImage: `url(${item.images[0]?.image_url})` }}
              >
                <div
                  className={classes.overlay}
                  onClick={() => handleFavoriteToggle(item.id)}
                >
                  {renderFavoriteIcon(item.id)}
                </div>
                <button
                  className={classes.add_to_cart_btn}
                  onClick={() => handleAddToCart(item.id)}
                >
                  + Ajouter au panier
                </button>
              </div>
            ))}
          </div>
        ))
      )}
    </Box>
  );
};

export default BestSellers;
