import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchFaqs } from '../../utils/http/faqService';
import AnswerToQuestion from './AnswerToQuestion';
import ProjectQuestions from './ProjectQuestions';
import SubscribeNewsletter from './../common/newsletter/SubscribeNewsletter';

function FrequentlyAskedQuestions() {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['faqs'],
    queryFn: fetchFaqs,
    staleTime: 5000,
    select: (data) => data.faqs || [],
  });

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (isError) {
    return <p>{error.message || "Erreur lors de la récupération des FAQ"}</p>;
  }

  const faqs = data.filter(faq => faq.project_related === 0);
  const relatedProjectFaqs = data.filter(faq => faq.project_related === 1);

  return (
    <>
      <AnswerToQuestion faqs={faqs} />
      <ProjectQuestions faqs={relatedProjectFaqs} />
      <SubscribeNewsletter />
    </>
  );
}

export default FrequentlyAskedQuestions;
