import React from "react";
import Cart from "../components/cart/Cart";
import BestSellers from "../components/home/best-sellers/BestSellers";
import SubscribeNewsletter from "../components/common/newsletter/SubscribeNewsletter";

function CartPage() {
  return (
    <>
      <Cart />
      <BestSellers />
      <SubscribeNewsletter />
    </>
  );
}

export default CartPage;
