import React from "react";
import Title from "../../ui/Title";
import classes from "./Store.module.css";
import Img1 from "./../../../assets/images/products/p4.jpeg";
import Img2 from "./../../../assets/images/products/p2.jpeg";
import Img3 from "./../../../assets/images/products/p3.jpeg";

function Store() {
  return (
    <section className={classes.store_container}>
      <Title title="NOTRE MAGASIN" />
      <div className={classes.store_content}>
        <div className={classes.box}>
          <div className={classes.store_images}>
            <div className={classes.first_container}>
              <img className={classes.image} src={Img1} alt="Product 1" />
            </div>
            <div className={classes.second_container}>
              <img className={classes.image} src={Img2} alt="Product 2" />
            </div>
            <div
              className={`${classes.third_container} ${classes.third_container_border}`}
            >
              <img className={classes.image} src={Img3} alt="Product 3" />
            </div>
          </div>
        </div>
        <div className={classes.store_text_content}>
          <h1 className={classes.bottom_title}>LES CHANDELLES</h1>
          <p className={classes.bottom_text}>
            Centre d'aide, Litiges et rapports, Protection des consommateurs,
            Régulation de la DPI, <br /> Informations réglementées, Conformité à
            l'intégrité.
          </p>
          <p className={classes.bottom_text}>
            Tous les produits populaires, Promotions, Prix bas, Excellent
            rapport qualité-prix, <br /> Commentaires, Blog, Portail vendeur,
            BLACK FRIDAY, Assistant.
          </p>
          <div className={classes.circle}>
            
          </div>
        </div>
      </div>
    </section>
  );
}

export default Store;
