import React from "react";
import Carousel from "react-material-ui-carousel";
import { Paper, Box } from "@mui/material";

function ImageCarousel({ images }) {
  return (
    <Box>
      <Carousel
        indicators={true}
        interval={3000}
        timeout={500}
        indicatorContainerProps={{
          style: {
            position: "absolute",
            bottom: "1em",
            left: "50%",
            transform: "translateX(-50%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color:"#fff",
            zIndex: "10",
          },
        }}
        indicatorIconButtonProps={{
          style: {
            padding: "0 8px",
            color:"#fff",
          },
        }}
        activeIndicatorIconButtonProps={{
          style: {
            color: "#EA6577",
          },
        }}
      >
        {images.map((image, i) => (
          <Paper key={i} elevation={0}>
            <Box
              component="img"
              sx={{
                width: "100%",
                height: "auto",
                aspectRatio: "1",
                objectFit: "cover",
              }}
              src={image.image_url}
              alt={`Slide ${i}`}
            />
          </Paper>
        ))}
      </Carousel>
    </Box>
  );
}

export default ImageCarousel;
