import React, { useState } from "react";
import { Form, Link } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { Alert } from "@mui/material";
import classes from "./AuthForm.module.css";
import AuthGoogle from "./../../assets/icons/auth_google.svg";
import AuthFacebook from "./../../assets/icons/auth_facebook.svg";
import {
  validateName,
  validateEmail,
  validatePassword,
  validatePasswordMatch,
} from "../../utils/validation";
import { submitRegisterForm } from "../../utils/http/registerService";

function RegisterForm() {
  const handleGoogleLogin = () => {
    window.location.href = "https://crm.leschandelles.ma/api/auth/google";
  };

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordConfirmationError, setPasswordConfirmationError] =
    useState("");

  const { mutate, isPending, isError, isSuccess, error } = useMutation({
    mutationFn: submitRegisterForm,
    onSuccess: (data) => {
      console.log("User registered successfully:", data);
    },
    onError: (error) => {
      console.error("Registration failed:", error.message);
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    const usernameValidationError = validateName(username);
    if (usernameValidationError) {
      setUsernameError(usernameValidationError);
    } else {
      setUsernameError("");
    }

    const emailValidationError = validateEmail(email);
    if (emailValidationError) {
      setEmailError(emailValidationError);
    } else {
      setEmailError("");
    }

    const passwordValidationError = validatePassword(password);
    if (passwordValidationError) {
      setPasswordError(passwordValidationError);
    } else {
      setPasswordError("");
    }

    const passwordMatchError = validatePasswordMatch(
      password,
      passwordConfirmation
    );
    if (passwordMatchError) {
      setPasswordConfirmationError(passwordMatchError);
    } else {
      setPasswordConfirmationError("");
    }

    if (
      !usernameValidationError &&
      !emailValidationError &&
      !passwordValidationError &&
      !passwordMatchError
    ) {
      const formData = {
        name: username,
        email,
        password,
        password_confirmation: passwordConfirmation,
      };
      console.log("Form data:", formData);
      mutate(formData);
    }
  };

  return (
    <div className={classes.form_container}>
      <h1 className={classes.title}>Créer un compte</h1>
      <p className={classes.redirect}>
        Vous avez déjà un compte ?{" "}
        <Link className={classes.redirect_link} to="/login">
          Se connecter
        </Link>
      </p>
      {isError && (
        <Alert severity="error">
          {error.message || "Une erreur est survenue. Veuillez réessayer."}
        </Alert>
      )}
      {isSuccess && (
        <Alert severity="success">
          Votre compte a été créé. Veuillez vérifier votre boîte de réception
          pour confirmer votre adresse e-mail avant de vous connecter.
        </Alert>
      )}
      <br />
      <Form onSubmit={handleSubmit}>
        <div className={classes.form_group}>
          <input
            type="text"
            name="name"
            placeholder="Entrez votre Prénom et nom"
            className={usernameError ? classes.input_error : classes.input}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          {usernameError && (
            <span className={classes.error_message}>{usernameError}</span>
          )}
        </div>
        <div className={classes.form_group}>
          <input
            type="text"
            name="email"
            placeholder="Entrez votre adresse e-mail"
            className={emailError ? classes.input_error : classes.input}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {emailError && (
            <span className={classes.error_message}>{emailError}</span>
          )}
        </div>
        <div className={classes.form_group}>
          <input
            type="password"
            name="password"
            placeholder="Entrez votre mot de passe."
            className={passwordError ? classes.input_error : classes.input}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {passwordError && (
            <span className={classes.error_message}>{passwordError}</span>
          )}
        </div>
        <div className={classes.form_group}>
          <input
            type="password"
            name="password_confirmation"
            placeholder="Confirmer votre mot de passe."
            className={
              passwordConfirmationError ? classes.input_error : classes.input
            }
            value={passwordConfirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
          />
          {passwordConfirmationError && (
            <span className={classes.error_message}>
              {passwordConfirmationError}
            </span>
          )}
        </div>
        <div className={`${classes.form_group} ${classes.remember_forgot}`}>
          <p className={classes.checkbox_container}>
            <input
              className={classes.checkbox}
              type="checkbox"
              name="remember"
              id="remember_me"
            />
            <label htmlFor="remember_me">Se souvenir de moi</label>
          </p>
        </div>
        <div>
          <button
            type="submit"
            className={classes.submit_button}
            disabled={isPending}
          >
            {isPending ? "Enregistrement en cours..." : "S'inscrire"}
          </button>
        </div>
        <p className={classes.use_other_method}>
          Ou utiliser d'autres méthodes.
        </p>
        <div
          className={classes.social_login}
          onClick={handleGoogleLogin}
          style={{ cursor: "pointer" }}
        >
          <img src={AuthGoogle} alt="Google" />
          <p>Continuer avec Google</p>
        </div>
        {false && (
          <div className={classes.social_login}>
            <img src={AuthFacebook} alt="Facebook" />
            <p>Continuer avec Facebook</p>
          </div>
        )}
      </Form>
    </div>
  );
}

export default RegisterForm;
