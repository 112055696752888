import React from "react";
import classes from "./Slogan.module.css";
function Slogan({ text }) {
  return (
    <div className={classes.slogan_container}>
      <h1 className={classes.slogan_title}>{text}</h1>
    </div>
  );
}

export default Slogan;
