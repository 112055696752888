import React from 'react'
import ContactUsContainer from './../components/contact/ContactUsContainer';

function ContactPage() {
  return (
    <div>
        <ContactUsContainer />
    </div>
  )
}

export default ContactPage