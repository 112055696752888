import React from "react";
import classes from "./Cart.module.css";
import Title from "../ui/Title";
import CartItems from "./CartItems";
import CartUserForm from "./CartUserForm";

function Cart() {
  return (
    <section>
      <Title title="Panier" />
      <div className={classes.cart_container}>
        <CartItems />
        <CartUserForm />
      </div>
    </section>
  );
}

export default Cart;
