import React from "react";
import { useMediaQuery } from "@mui/material";
import AuthImage from "../components/auth/AuthImage";
import ResetPasswordForm from "../components/auth/ResetPasswordForm";
import MobileResetPasswordForm from "../components/auth/MobileResetPasswordForm";

function ResetPasswordPage() {
  const isMobile = useMediaQuery("(max-width:768px)");

  return (
    <>
      {!isMobile ? (
        <section style={{ display: "flex" }}>
          <AuthImage />
          <ResetPasswordForm />
        </section>
      ) : (
        <MobileResetPasswordForm open />
      )}
    </>
  );
}

export default ResetPasswordPage;
