import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { Alert, Grid } from "@mui/material";
import classes from "./UserInfoForm.module.css";
import {
  validatePassword,
  validatePasswordMatch,
} from "../../utils/validation";
import { submitChangePasswordForm } from "../../utils/http/passwordService";

function ChangePasswordForm() {
  const [sucessMessage, setSucessMessage] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [currentPasswordError, setCurrentPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [passwordConfirmationError, setPasswordConfirmationError] = useState("");

  const { mutate, isPending, isError, isSuccess, error } = useMutation({
    mutationFn: submitChangePasswordForm,
    onSuccess: (data) => {
      setSucessMessage(data?.message || "Mot de passe changé avec succès.");
    },
    onError: (error) => {
      console.error("Login failed:", error.message);
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!currentPassword) {
      setCurrentPasswordError("Le mot de passe actuel est requis.");
    } else {
      setCurrentPasswordError("");
    }
    const newPasswordValidationError = validatePassword(newPassword);
    if (newPasswordValidationError) {
      setNewPasswordError(newPasswordValidationError);
    } else {
      setNewPasswordError("");
    }
    const passwordMatchError = validatePasswordMatch(
      newPassword,
      passwordConfirmation
    );
    if (passwordMatchError) {
      setPasswordConfirmationError(passwordMatchError);
    } else {
      setPasswordConfirmationError("");
    }

    if (
      !currentPasswordError &&
      !newPasswordValidationError &&
      !passwordMatchError
    ) {
      const formData = {
        currentpassword: currentPassword,
        newpassword: newPassword,
        newpassword_confirmation: passwordConfirmation,
      };
      mutate(formData);
    }
  };

  return (
    <Grid
      container
      spacing={4}
      className={classes.user_form}
      component="form"
      onSubmit={handleSubmit}
    >
      <Grid item xs={12}>
        {isError && (
          <Alert severity="error">
            {error.message || "Une erreur est survenue. Veuillez réessayer."}
          </Alert>
        )}
        {isSuccess && <Alert severity="success">{sucessMessage}</Alert>}
      </Grid>
      <Grid item xs={12}>
        <label htmlFor="current_password">Mot de passe actuel</label>
        <input
          type="password"
          id="current_password"
          name="currentpassword"
          className={currentPasswordError ? classes.input_error : classes.input}
          placeholder="Entrez votre mot de passe actuel"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
        />
        {currentPasswordError && (
          <span className={classes.error_message}>{currentPasswordError}</span>
        )}
      </Grid>
      <Grid item xs={12}>
        <label htmlFor="new_password">Nouveau mot de passe</label>
        <input
          type="password"
          id="new_password"
          name="newpassword"
          className={newPasswordError ? classes.input_error : classes.input}
          placeholder="Entrez votre nouveau mot de passe"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        {newPasswordError && (
          <span className={classes.error_message}>{newPasswordError}</span>
        )}
      </Grid>
      <Grid item xs={12}>
        <label htmlFor="new_password_confirmation">
          Confirmer mot de passe
        </label>
        <input
          type="password"
          id="new_password_confirmation"
          name="newpassword_confirmation"
          className={
            passwordConfirmationError ? classes.input_error : classes.input
          }
          placeholder="Confirmez votre nouveau mot de passe"
          value={passwordConfirmation}
          onChange={(e) => setPasswordConfirmation(e.target.value)}
        />
        {passwordConfirmationError && (
          <span className={classes.error_message}>
            {passwordConfirmationError}
          </span>
        )}
      </Grid>
      <Grid item xs={12}>
        <button className={classes.submit_btn} type="submit">
          {isPending ? "Sauvegarde en cours..." : "Sauvegarder"}
        </button>
      </Grid>
    </Grid>
  );
}

export default ChangePasswordForm;
