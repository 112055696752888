import React, { useState } from "react";
import { Form, Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { Alert } from "@mui/material";
import classes from "./AuthForm.module.css";
import AuthGoogle from "./../../assets/icons/auth_google.svg";
import AuthFacebook from "./../../assets/icons/auth_facebook.svg";
import { validateEmail } from "../../utils/validation";
import { submitLoginForm } from "../../utils/http/loginService";
import { setCookie } from "../../utils/functions";
import {
  checkAuthStatus,
  login as loginAction,
} from "../../redux/slices/authSlice";

function LoginForm() {
  const handleGoogleLogin = () => {
    window.location.href = "https://crm.leschandelles.ma/api/auth/google";
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");

  const { mutate, isPending, isError, isSuccess, error } = useMutation({
    mutationFn: submitLoginForm,
    onSuccess: (data) => {
      setCookie("token", data.token, 1);
      dispatch(loginAction());
      dispatch(checkAuthStatus());
      navigate("/");
    },
    onError: (error) => {
      console.error("Login failed:", error.message);
    },
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    const emailValidationError = validateEmail(email);
    if (emailValidationError) {
      setEmailError(emailValidationError);
    } else {
      const formData = {
        email,
        password,
      };
      mutate(formData);
    }
  };

  return (
    <div className={classes.form_container}>
      <h1 className={classes.title}>Se connecter.</h1>
      <p className={classes.redirect}>
        Vous n'avez pas encore de compte ?{" "}
        <Link className={classes.redirect_link} to="/register">
          Créer
        </Link>
      </p>
      {isError && (
        <Alert severity="error">
          {error.message || "Une erreur est survenue. Veuillez réessayer."}
        </Alert>
      )}
      {isSuccess && (
        <Alert severity="success">Vous avez correctement connecter.</Alert>
      )}
      <br />
      <Form onSubmit={handleSubmit}>
        <div className={classes.form_group}>
          <input
            type="text"
            name="email"
            placeholder="Entrez votre adresse e-mail"
            className={emailError ? classes.input_error : classes.input}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {emailError && (
            <span className={classes.error_message}>{emailError}</span>
          )}
        </div>
        <div className={classes.form_group}>
          <input
            type="password"
            name="password"
            placeholder="Entrez votre mot de passe."
            className={classes.input}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className={`${classes.form_group} ${classes.remember_forgot}`}>
          <p className={classes.checkbox_container}>
            <input
              className={classes.checkbox}
              type="checkbox"
              name="remember"
              id="remember_me"
            />
            <label htmlFor="remember_me">Se souvenir de moi</label>
          </p>
          <p>
            <Link className={classes.redirect_link} to="/forgot-password">
              Mot de passe oublié ?
            </Link>
          </p>
        </div>
        <div>
          <button type="submit" className={classes.submit_button}>
            {isPending ? "Connexion en cours..." : "Connexion"}
          </button>
        </div>
        <p className={classes.use_other_method}>
          Ou utiliser d'autres méthodes.
        </p>
        <div
          className={classes.social_login}
          onClick={handleGoogleLogin}
          style={{ cursor: "pointer" }}
        >
          <img src={AuthGoogle} alt="Google" />
          <p>Continuer avec Google</p>
        </div>
        {false && (
          <div className={classes.social_login}>
            <img src={AuthFacebook} alt="Facebook" />
            <p>Continuer avec Facebook</p>
          </div>
        )}
      </Form>
    </div>
  );
}

export default LoginForm;
