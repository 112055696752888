import React from "react";
import Title from "./../../ui/Title";
import classes from "./Features.module.css";
import FeatureCard from "./../../ui/FeatureCard";
import HandMade from "./../../../assets/icons/about/Hand made.svg";
import Badge from "./../../../assets/icons/about/badge.svg";
import BestPrice from "./../../../assets/icons/about/Best price.svg";
import TechSupport from "./../../../assets/icons/about/Tech support.svg";

function Features() {
  const featuresData = [
    {
      icon: HandMade,
      title: "HAND MADE",
      text: "Lorem ipsum dolor sit amet",
    },
    {
      icon: Badge,
      title: "QUALITE",
      text: "Lorem ipsum dolor sit amet",
    },
    {
      icon: BestPrice,
      title: "PRIX",
      text: "Lorem ipsum dolor sit amet",
    },
    {
      icon: TechSupport,
      title: "SERVICE",
      text: "Lorem ipsum dolor sit amet",
    },
  ];

  return (
    <section className={classes.features_container}>
      <Title title="CE QUE NOUS OFFRONS" />
      <div className={classes.features}>
        {featuresData.map((feature, index) => (
          <FeatureCard
            key={index}
            icon={feature.icon}
            title={feature.title}
            text={feature.text}
          />
        ))}
      </div>
    </section>
  );
}

export default Features;
