import React from "react";
import { Link } from "react-router-dom";
import classes from "./AuthImage.module.css";
import AuthImg from "./../../assets/images/auth-img.png";
import SelectArrow from "./../../assets/icons/SelectArrow.svg";
function AuthImage() {
  return (
    <section className={classes.auth_img_container}>
      <Link to="/" className={classes.redirect_btn}>
        <img src={SelectArrow} alt="Arrow" className={classes.select_arrow} />
      </Link>
      <img src={AuthImg} alt="Auth" className={classes.auth_img} />
      <div className={classes.slogon}>
        <p>Crafting light, Sharing delight</p>
      </div>
    </section>
  );
}

export default AuthImage;
