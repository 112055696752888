import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedPerfume: "Nos parfum",
};

const selectionSlice = createSlice({
  name: "selection",
  initialState,
  reducers: {
    setSelectedPerfume: (state, action) => {
      state.selectedPerfume = action.payload;
    },
  },
});

export const { setSelectedPerfume } = selectionSlice.actions;
export default selectionSlice.reducer;
