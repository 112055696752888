import React from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Whatsapp from "./../../../assets/icons/whatsapp-square.svg";
import Instagram from "./../../../assets/icons/instagram-square.svg";
import Facebook from "./../../../assets/icons/facebook-square.svg";
import Location from "./../../../assets/icons/location-square.svg";
import classes from "./Hero.module.css";

function Hero() {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const navigate = useNavigate();
  return (
    <div className={classes.hero_container}>
      <div className={classes.social_links}>
        <Link
          to="https://wa.me/+212637222323"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Whatsapp} alt="Whatsapp" className={classes.social_link} />
        </Link>
        <Link
          to="https://www.instagram.com/les_chandelles_/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={Instagram}
            alt="Instagram"
            className={classes.social_link}
          />
        </Link>
        <Link
          to="https://facebook.com/people/les-chandelles/61553683991616/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Facebook} alt="Facebook" className={classes.social_link} />
        </Link>
        <Link
          to="https://maps.app.goo.gl/V7dg9jPcudWrvVLGA"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Location} alt="Location" className={classes.social_link} />
        </Link>
      </div>
      <div className={classes.hero_titles}>
        <h1>LES CHANDELLES</h1>
        <h2>
          Atelier de Fabrication de
          <br /> Bougies Personnalisées.
        </h2>
      </div>
      <button
        onClick={() => navigate(isLoggedIn ? "/profile" : "/register")}
        className={classes.hero_btn}
      >
        {isLoggedIn ? "Voir mon compte" : "Créer un compte"}
      </button>
    </div>
  );
}

export default Hero;
