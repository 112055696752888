import { useMemo } from "react";
import { useRouteError } from "react-router-dom";
import classes from "./Error.module.css";
import Title from "./../ui/Title";

function Error() {
  const error = useRouteError();
  const { status, data } = error || {};

  const { title, message } = useMemo(() => {
    let title = "Une erreur s'est produite !";
    let message = "Quelque chose s'est mal passé !";

    if (status === 500 && data?.message) {
      message = data.message;
    }

    if (status === 404) {
      title = "Page non trouvée !";
      message = "La ressource ou la page demandée est introuvable.";
    }

    return { title, message };
  }, [status, data]);

  return (
    <div className={classes.container}>
      <h1 className={classes.status}>{status ? status : "Erreur"}</h1>
      <Title title={title} /> 
      <p className={classes.message}>{message}</p>
    </div>
  );
}

export default Error;
