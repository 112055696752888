import React from "react";
import Title from "./../../ui/Title";
import classes from "./AboutUs.module.css";

import firstImg from "./../../../assets/images/about/first-img.png";
import secondImg from "./../../../assets/images/about/second-img.png";
import thirdImg from "./../../../assets/images/about/third-img.png";

import Img1 from "./../../../assets/images/about/img1.png";
import Img2 from "./../../../assets/images/about/img2.png";
import Img3 from "./../../../assets/images/about/img3.png";
import Img4 from "./../../../assets/images/about/img4.png";
import Img5 from "./../../../assets/images/about/img5.png";
import Img6 from "./../../../assets/images/about/img6.png";
import Img7 from "./../../../assets/images/about/img7.png";

function AboutUs() {
  return (
    <section className={classes.about_us_container}>
      <Title title="À PROPOS DE NOUS" />
      <div className={classes.about_us_text}>
        Tous les populaires, Produit, Promotion, Prix bas, Excellent rapport
        qualité-prix, Commentaires, Blog, Portail vendeur, BLACK FRIDAY,
        Assistant Centre d'aide, Litiges et rapports, Protection des acheteurs,
        Signaler une violation des DPI, Informations réglementées, Conformité à
        l'intégrité
      </div>
      <div className={classes.about_us_images_container}>
        <div className={classes.about_us_images}>
          <div>
            <img src={firstImg} alt="first img" />
            <img src={Img1} alt="Img 1" className={classes.img1} />
            <img src={Img2} alt="Img 2" className={classes.img2} />
          </div>
          <div>
            <img src={secondImg} alt="second img" />
            <img src={Img3} alt="Img 3" className={classes.img3} />
            <img src={Img4} alt="Img 4" className={classes.img4} />
            <img src={Img5} alt="Img 5" className={classes.img5} />
          </div>
          <div>
            <img src={thirdImg} alt="third img" />
            <img src={Img6} alt="Img 6" className={classes.img6} />
            <img src={Img7} alt="Img 7" className={classes.img7} />
          </div>
        </div>
      </div>
      <h1 className={classes.bottom_title}>
        Fabrication de bougies parfumées 100 %
      </h1>
    </section>
  );
}

export default AboutUs;
