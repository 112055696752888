import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import EastIcon from "@mui/icons-material/East";
import { Alert, Box, CircularProgress } from "@mui/material";
import {
  validateName,
  validateEmail,
  validatePhoneNumber,
} from "../../utils/validation";
import classes from "./CartUserForm.module.css";
import { getUser } from "../../utils/http/userService";
import { createOrder } from "../../utils/http/orderService";
import { clearCart } from "../../redux/slices/cartSlice";

function CartUserForm() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const auth = useSelector((state) => state.auth.isLoggedIn);
  const totalPrice = useSelector((state) => state.cart.totalPrice);

  const {
    data: user,
    isError: isUserError,
    isLoading: isUserLoading,
    error: userError,
  } = useQuery({
    queryKey: ["user"],
    queryFn: getUser,
    staleTime: 5000,
    enabled: auth,
  });

  const {
    mutate: submitOrder,
    isPending,
    isError,
    isSuccess,
    error,
  } = useMutation({
    mutationFn: createOrder,
    onSuccess: (data) => {
      queryClient.invalidateQueries("orders");
      dispatch(clearCart());
      setSuccessMessage(data?.message || "Commande crée avec succès !");
    },
    onError: (error) => {
      console.error("Échec de la création de la commande:", error.message);
    },
  });

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    if (user && user.data) {
      setName(user.data.name || "");
      setEmail(user.data.email || "");
      setPhone(user.data.phone_number || "");
      setAddress(user.data.address || "");
    }
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const nameValidationError = validateName(name);
    const emailValidationError = validateEmail(email);
    const phoneValidationError = validatePhoneNumber(phone);
    const addressValidationError = address ? "" : "L'adresse est requise.";

    setNameError(nameValidationError || "");
    setEmailError(emailValidationError || "");
    setPhoneError(phoneValidationError || "");
    setAddressError(addressValidationError);

    if (
      !nameValidationError &&
      !emailValidationError &&
      !phoneValidationError &&
      !addressValidationError
    ) {
      submitOrder({
        user_name: name,
        email,
        phone,
        address,
      });
    }
  };

  return (
    <section className={classes.cart_user_form_container}>
      <div className={classes.card}>
        <div className={classes.header}>
          <h1>Détails de la livraison</h1>
          <img
            src="https://th.bing.com/th/id/R.78cf040e801070f3393c1cde8e41056f?rik=AxjC93yPxB7Nig&pid=ImgRaw&r=0"
            alt="Delivery"
            className={classes.header_image}
          />
        </div>
        {auth && isUserLoading && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="10vh"
          >
            <CircularProgress />
          </Box>
        )}
        {auth && isUserError && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="10vh"
          >
            <Alert severity="error">
              {userError?.message ||
                "Erreur lors de la récupération des données."}
            </Alert>
          </Box>
        )}
        <form className={classes.form} onSubmit={handleSubmit}>
          {isSuccess && <Alert severity="success">{successMessage}</Alert>}
          {isError && (
            <Alert severity="error">
              {error?.message || "Erreur lors de la création de la commande."}
            </Alert>
          )}
          <div className={classes.form_group}>
            <label htmlFor="firstName_lastName">Nom et Prénom</label>
            <input
              type="text"
              id="firstName_lastName"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className={nameError ? classes.input_error : classes.input}
            />
            {nameError && (
              <span className={classes.error_message}>{nameError}</span>
            )}
          </div>
          <div className={classes.form_group}>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={emailError ? classes.input_error : classes.input}
            />
            {emailError && (
              <span className={classes.error_message}>{emailError}</span>
            )}
          </div>
          <div className={classes.form_group}>
            <label htmlFor="phone">Téléphone</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className={phoneError ? classes.input_error : classes.input}
            />
            {phoneError && (
              <span className={classes.error_message}>{phoneError}</span>
            )}
          </div>
          <div className={classes.form_group}>
            <label htmlFor="address">Adresse</label>
            <input
              type="text"
              id="address"
              name="address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              className={addressError ? classes.input_error : classes.input}
            />
            {addressError && (
              <span className={classes.error_message}>{addressError}</span>
            )}
          </div>
          <div className={classes.divider}></div>
          <div className={classes.summary}>
            <div className={classes.summary_item}>
              <p>Total</p>
              <p>{totalPrice}DH</p>
            </div>
            <div className={classes.summary_item}>
              <p>Expédition</p>
              <p>De 0 à 55 MAD</p>
            </div>
          </div>
          <button
            disabled={!auth || isPending}
            style={{ cursor: auth && !isPending ? "pointer" : "not-allowed" }}
            className={classes.checkout}
            type="submit"
          >
            {isPending && <CircularProgress size={24} color="inherit" />}
            <p>
              Total: {totalPrice}MAD - {totalPrice + 55}MAD
            </p>
            <div>
              <span>Checkout</span>
              <EastIcon />
            </div>
          </button>
        </form>
      </div>
    </section>
  );
}

export default CartUserForm;
