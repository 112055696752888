import axios from "axios";
import { ApiRoutes } from "../../apis/ApiRoutes";
import { getCookie } from "../functions";

const createApiClient = () => {
  const token = getCookie("token");
  return axios.create({
    baseURL: ApiRoutes.baseURL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createOrder = async (deliveryData) => {
  const apiClient = createApiClient();
  try {
    const response = await apiClient.post(ApiRoutes.order(), deliveryData);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(
        error.response.data.message ||
          "Erreur lors de la création de la commande"
      );
    } else if (error.request) {
      throw new Error(
        "Aucune réponse du serveur. Veuillez réessayer plus tard."
      );
    } else {
      throw new Error("Erreur dans la configuration de la requête.");
    }
  }
};

export const getUserOrders = async () => {
  const apiClient = createApiClient();
  try {
    const response = await apiClient.get(ApiRoutes.order());
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(
        error.response.data.message ||
          "Erreur lors de la recuperation des commandes"
      );
    } else if (error.request) {
      throw new Error(
        "Aucune réponse du serveur. Veuillez réessayer plus tard."
      );
    } else {
      throw new Error("Erreur dans la configuration de la requête.");
    }
  }
};
