import React from "react";
import classes from "./Infobar.module.css";
function Infobar() {
  return (
    <div className={classes.infobar_conatiner}>
      <h1 className={classes.infobar_text}>Livraison partout au Maroc</h1>
    </div>
  );
}

export default Infobar;
