import { combineReducers } from "redux";
import cartReducer from "../slices/cartSlice";
import perfumeReducer from "../slices/perfumeSlice";
import authReducer from "../slices/authSlice";
import favoritesReducer from "../slices/favoritesSlice";

const rootReducer = combineReducers({
  cart: cartReducer,
  perfume: perfumeReducer,
  auth: authReducer,
  favorites: favoritesReducer,
});

export default rootReducer;
