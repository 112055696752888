import React from "react";
import classes from "./FeatureCard.module.css";
function FeatureCard({ icon, title, text }) {
  return (
    <section className={classes.feature_container}>
      <div className={classes.feature_icon}>
        <img src={icon} alt="feature icon" />
      </div>
      <h1 className={classes.feature_title}>{title}</h1>
      <p className={classes.feature_text}>{text}</p>
    </section>
  );
}

export default FeatureCard;
