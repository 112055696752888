export function translateStatus(status) {
  switch (status) {
    case "pending":
      return "En cours";
    case "validated":
      return "Validé";
    case "in_transit":
      return "En route";
    case "delivered":
      return "Livré";
    case "canceled":
      return "Annulé";
    default:
      return "Statut inconnu";
  }
}
