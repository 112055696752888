import axios from "axios";
import { ApiRoutes } from "../../apis/ApiRoutes";
import { getCookie } from "./getCookie";

export async function checkAuth() {
  const token = getCookie("token");
  if (!token) {
    return false;
  }
  try {
    const response = await axios.get(ApiRoutes.checkToken(), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      return true;
    } else {
      console.log("Token invalide");
      return false;
    }
  } catch (error) {
    console.error("La validation du jeton a échoué", error);
  }
}
