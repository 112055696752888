import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { Link, useParams, useNavigate } from "react-router-dom"; 
import {
  useMediaQuery,
  useTheme,
  Menu,
  MenuItem,
  Box,
  CircularProgress,
} from "@mui/material";
import { setSelectedPerfume } from "../../redux/slices/perfumeSlice";
import { fetchPerfumes } from "../../utils/http/perfumeService";
import SelectArrow from "./../../assets/icons/SelectArrow.svg";
import classes from "./ProductsHeader.module.css";

function ProductsHeader() {
  const { id } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();

  const [perfumeAnchorEl, setPerfumeAnchorEl] = useState(null);
  const [priceAnchorEl, setPriceAnchorEl] = useState(null);
  const selectedPerfume = useSelector((state) => state.perfume.selectedPerfume);
  const selectedPrice = 10;

  const {
    data: perfumesData,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["perfumes"],
    queryFn: fetchPerfumes,
    staleTime: 5000,
    select: (data) => data.perfumes || [],
  });

  const perfumes = useMemo(() => perfumesData || [], [perfumesData]);

  useEffect(() => {
    if (selectedPerfume === "Nos parfum" && perfumes.length > 0 && id) {
      const perfumeById = perfumes.find(
        (perfume) => parseInt(perfume.id) === parseInt(id)
      );
      if (perfumeById) {
        dispatch(setSelectedPerfume(perfumeById.name));
      }
    }
  }, [id, perfumes, selectedPerfume, dispatch]);

  const handlePerfumeClick = (event) => {
    setPerfumeAnchorEl(event.currentTarget);
  };

  const handlePriceClick = (event) => {
    setPriceAnchorEl(event.currentTarget);
  };

  const handlePerfumeClose = (perfume) => {
    setPerfumeAnchorEl(null);
    if (perfume) {
      dispatch(setSelectedPerfume(perfume.name));
      navigate(`/perfumes/${perfume.id}`);
    }
  };

  const handlePriceClose = (price) => {
    setPriceAnchorEl(null);
  };

  if (isError) {
    console.log(
      error.message || "Erreur lors de la récupération des collections."
    );
  }

  const prices = [
    "Prix 1",
    "Prix 2",
    "Prix 3",
    "Prix 4",
    "Prix 5",
    "Prix 6",
    "Prix 7",
    "Prix 8",
    "Prix 9",
    "Prix 10",
  ];

  const renderMenu = (anchorEl, handleClose, items, selectedItem) => {
    if (isLoading) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      );
    }

    return (
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => handleClose()}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        PaperProps={{
          style: {
            maxHeight: "200px",
            width: anchorEl ? anchorEl.clientWidth : undefined,
          },
        }}
      >
        {items.map((item) => (
          <MenuItem
            key={item.id || item}
            selected={item.name === selectedItem || item === selectedItem}
            onClick={() => handleClose(item)}
          >
            {item.name || item}
          </MenuItem>
        ))}
      </Menu>
    );
  };

  return (
    <>
      {!isSmallScreen ? (
        <section>
          <div className={classes.products_header_container}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Link to="/">
                <div className={classes.categories}>
                  <div className={classes.select_arrow_container}>
                    <img
                      src={SelectArrow}
                      className={classes.select_arrow_icon}
                      alt="Select Arrow"
                    />
                  </div>
                  <p>Toutes les catégories</p>
                </div>
              </Link>
              <div className={classes.title}>
                <h1 className={classes.products_header_title}>
                  {selectedPerfume !== "Nos parfums"
                    ? selectedPerfume
                    : "Nos parfums"}
                </h1>
              </div>
              <div className={classes.perfumes} onClick={handlePerfumeClick}>
                <p>{selectedPerfume || "Nos parfums"}</p>
                <div className={classes.select_arrow_perfumes_container}>
                  <img
                    src={SelectArrow}
                    className={classes.select_arrow_icon}
                    alt="Select Arrow"
                  />
                </div>
              </div>
              {renderMenu(
                perfumeAnchorEl,
                handlePerfumeClose,
                perfumes,
                selectedPerfume
              )}
            </div>
            <div className={classes.filter_section}>
              <p className={classes.filter_by}>TRIER PAR :</p>
              <div>
                <select
                  name="type"
                  className={classes.select}
                  title="Trier par prix"
                >
                  <option hidden value="">
                    Popularité
                  </option>
                  <option value="">Tous</option>
                </select>
              </div>
              <div>
                <select
                  name="popularite"
                  className={classes.select}
                  title="Trier par popularité"
                >
                  <option hidden value="">
                    Prix
                  </option>
                  <option value="">Tous</option>
                  {prices.map((price) => (
                    <option value="" key={price}>{price}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section>
          <div className={classes.products_header_container}>
            <h1 className={classes.products_header_title}>CARMÉLITE</h1>
          </div>
          <div className={classes.filters}>
            <div className={classes.filter_by_price} onClick={handlePriceClick}>
              <p>{selectedPrice || "Prix"}</p>
              <div className={classes.select_arrow_perfumes_container}>
                <img
                  src={SelectArrow}
                  className={classes.select_arrow_icon}
                  alt="Select Arrow"
                />
              </div>
            </div>
            {renderMenu(priceAnchorEl, handlePriceClose, prices, selectedPrice)}
            <div
              className={classes.filter_by_perfumes}
              onClick={handlePerfumeClick}
            >
              <p>{selectedPerfume || "Nos parfums"}</p>
              <div className={classes.select_arrow_perfumes_container}>
                <img
                  src={SelectArrow}
                  className={classes.select_arrow_icon}
                  alt="Select Arrow"
                />
              </div>
            </div>
            {renderMenu(
              perfumeAnchorEl,
              handlePerfumeClose,
              perfumes,
              selectedPerfume
            )}
          </div>
        </section>
      )}
    </>
  );
}

export default ProductsHeader;
