const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const ApiRoutes = {
  contact: () => `${API_BASE_URL}/contact`,
  newsletter: () => `${API_BASE_URL}/newsletter`,
  perfumes: () => `${API_BASE_URL}/perfumes`,
  faqs: () => `${API_BASE_URL}/faqs`,
  collections: () => `${API_BASE_URL}/collections`,
  products: () => `${API_BASE_URL}/products`,
  product: (id) => `${API_BASE_URL}/products/${id}`,
  register: () => `${API_BASE_URL}/register`,
  login: () => `${API_BASE_URL}/login`,
  checkToken: () => `${API_BASE_URL}/check-token`,
  logout: () => `${API_BASE_URL}/logout`,
  user: () => `${API_BASE_URL}/user`,
  updatePassword: () => `${API_BASE_URL}/update-password`,
  updateProfile: () => `${API_BASE_URL}/update-profile`,
  forgotPassword: () => `${API_BASE_URL}/forgot-password`,
  resetPassword: () => `${API_BASE_URL}/reset-password`,
  favorites: () => `${API_BASE_URL}/favorites`,
  addfavorites: (id) => `${API_BASE_URL}/favorites/${id}`,
  removeFavorite: (id) => `${API_BASE_URL}/destroy/favorites/${id}`,
  carts: () => `${API_BASE_URL}/carts`,
  addLocalItemsToDb: () => `${API_BASE_URL}/carts/add-items`,
  removefromcart: (id) => `${API_BASE_URL}/carts/${id}`,
  order: () => `${API_BASE_URL}/orders`,
  bestsellers: () => `${API_BASE_URL}/best/sellers`,
  authGoogle: () => `${API_BASE_URL}/auth/google`,
};
