import React, { Fragment } from "react";
import AboutHero from "../components/about/hero/AboutHero";
import AboutUs from "../components/about/about-us/AboutUs";
import Features from "../components/about/feature/Features";
import Store from "../components/about/store/Store";
import SubscribeNewsletter from './../components/common/newsletter/SubscribeNewsletter';

function AboutPage() {
  return (
    <Fragment>
      <AboutHero />
      <AboutUs />
      <Features />
      <Store />
      <SubscribeNewsletter />
    </Fragment>
  );
}

export default AboutPage;
