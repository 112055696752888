import React, { useState } from "react";
import { Form, Link, useSearchParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { Alert } from "@mui/material";

import classes from "./AuthForm.module.css";
import AuthGoogle from "./../../assets/icons/auth_google.svg";
import AuthFacebook from "./../../assets/icons/auth_facebook.svg";
import {
  validatePassword,
  validatePasswordMatch,
} from "../../utils/validation";
import { submitResetPasswordForm } from "../../utils/http/resetPasswordService";

function ResetPasswordForm() {
  const handleGoogleLogin = () => {
    window.location.href = "https://crm.leschandelles.ma/api/auth/google";
  };

  const [params] = useSearchParams();
  const email = params.get("email");
  const token = params.get("token");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const { mutate, isPending, isError, isSuccess, error } = useMutation({
    mutationFn: submitResetPasswordForm,
    onSuccess: (data) => {
      console.log("User registered successfully:", data);
    },
    onError: (error) => {
      console.error("Registration failed:", error.message);
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const passwordValidationError = validatePassword(password);
    if (passwordValidationError) {
      setPasswordError(passwordValidationError);
      setConfirmPasswordError("");
      return;
    }
    setPasswordError("");
    const passwordMatchError = validatePasswordMatch(password, confirmPassword);
    if (passwordMatchError) {
      setConfirmPasswordError(passwordMatchError);
    } else {
      const formData = {
        email,
        token,
        password,
        password_confirmation: confirmPassword,
      };
      mutate(formData);
    }
  };
  return (
    <div className={classes.form_container}>
      <h1 className={classes.title}>Réinitialiser le mot de passe</h1>
      <p className={classes.redirect}>
        Vous n'avez pas encore de compte ?{" "}
        <Link className={classes.redirect_link} to="/register">
          Créer
        </Link>
      </p>
      {isError && (
        <Alert severity="error">
          {error.message || "Une erreur est survenue. Veuillez réessayer."}
        </Alert>
      )}
      {isSuccess && (
        <Alert severity="success">
          Votre mot de passe a été réinitialisé avec succès.
        </Alert>
      )}
      <br />
      <Form onSubmit={handleSubmit}>
        <input type="hidden" name="token" value={token} />
        <div className={classes.form_group}>
          <input
            type="email"
            name="email"
            placeholder="Entrez votre email"
            className={classes.input}
            value={email}
            readOnly
          />
        </div>
        <div className={classes.form_group}>
          <input
            type="password"
            name="password"
            placeholder="Entrez votre mot de passe"
            className={passwordError ? classes.input_error : classes.input}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {passwordError && (
            <span className={classes.error_message}>{passwordError}</span>
          )}
        </div>
        <div className={classes.form_group}>
          <input
            type="password"
            name="confirmPassword"
            placeholder="Confirmez votre mot de passe"
            className={
              confirmPasswordError ? classes.input_error : classes.input
            }
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {confirmPasswordError && (
            <span className={classes.error_message}>
              {confirmPasswordError}
            </span>
          )}
        </div>
        <div className={classes.form_group}>
          <p className={classes.checkbox_container}>
            <input
              className={classes.checkbox}
              type="checkbox"
              name="remember"
              id="remember_me"
            />
            <label htmlFor="remember_me">Se souvenir de moi</label>
          </p>
        </div>
        <div>
          <button type="submit" className={classes.submit_button}>
            {isPending
              ? "Réinitialisation en cours..."
              : "Réinitialiser le mot de passe"}
          </button>
        </div>
        <p className={classes.use_other_method}>
          Ou utiliser d'autres méthodes
        </p>
        <div
          onClick={handleGoogleLogin}
          className={classes.social_login}
          style={{ cursor: "pointer" }}
        >
          <img src={AuthGoogle} alt="Google" />
          <p>Continuer avec Google</p>
        </div>
        {false && (
          <div className={classes.social_login}>
            <img src={AuthFacebook} alt="Facebook" />
            <p>Continuer avec Facebook</p>
          </div>
        )}
      </Form>
    </div>
  );
}

export default ResetPasswordForm;
