import React from 'react'
import Profile from '../components/profile/Profile'
import SubscribeNewsletter from '../components/common/newsletter/SubscribeNewsletter'

function ProfilePage() {
  return (
   <> 
    <Profile/>
    <SubscribeNewsletter />
    </>
  )
}

export default ProfilePage