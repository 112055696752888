import React from 'react'
import FrequentlyAskedQuestions from '../components/faq/FrequentlyAskedQuestions'

function FrequentlyAskedQuestionsPage() {
  return (
    <div>
        <FrequentlyAskedQuestions/>
    </div>
  )
}

export default FrequentlyAskedQuestionsPage