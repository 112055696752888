import React from "react";
import classes from "./ColoredCircle.module.css";

function ColoredCircle({ bgColor, isActive, onClick }) {
  return (
    <input
      type="radio"
      className={`${classes.colored_circle} ${isActive ? classes.active : ""}`}
      style={{ backgroundColor: bgColor }}
      checked={isActive}
      onChange={onClick}
    />
  );
}

export default ColoredCircle;
