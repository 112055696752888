import axios from "axios";
import { ApiRoutes } from "../../apis/ApiRoutes";
import { getCookie } from "../functions";

const createApiClient = () => {
  const token = getCookie("token");
  return axios.create({
    baseURL: ApiRoutes.baseURL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const submiteUpdateProfile = async (formData) => {
  const apiClient = createApiClient();
  try {
    const response = await apiClient.post(ApiRoutes.updateProfile(), formData);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(
        error.response.data.message || "Erreur de soumission du formulaire"
      );
    } else if (error.request) {
      throw new Error("Aucune réponse du serveur");
    } else {
      throw new Error("Erreur dans la configuration de la requête");
    }
  }
};
