import React, { useEffect, useState } from "react";
import { Box, Grid, Badge, Menu, MenuItem, Popover } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "../../../utils/http/logoutService";
import { checkAuthStatus } from "./../../../redux/slices/authSlice";
import TemporaryDrawer from "./TemporaryDrawer";
import classes from "./Navbar.module.css";
import Bars from "./../../../assets/icons/Bars.svg";
import Morocco from "./../../../assets/images/Morocco.svg";
import France from "./../../../assets/images/France.svg";
import USA from "./../../../assets/images/USA.svg";
import SelectArrow from "./../../../assets/icons/SelectArrow.svg";
import Logo from "./../../../assets/images/leschandelleslogo.png";
import Heart from "./../../../assets/icons/Heart.svg";
import Magnifying from "./../../../assets/icons/Magnifiying Glass.svg";
import User from "./../../../assets/icons/User.svg";
import Bag from "./../../../assets/icons/Shopping Bag.svg";
import Login from "./../../../assets/icons/login.svg";
import Logout from "./../../../assets/icons/logout.svg";
import SearchBar from "../search/SearchBar";

function Navbar() {
  useEffect(() => {
    const storedCurrency = localStorage.getItem("selectedCurrency");
    if (storedCurrency) {
      handleCurrencyClose(storedCurrency);
    }
  }, []);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchAnchorEl, setSearchAnchorEl] = useState(null);
  const [currency, setCurrency] = useState("MAD");
  const [currencyFlag, setCurrencyFlag] = useState(Morocco);

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const totalQuantity = useSelector((state) => state.cart.totalQuantity);
  const favorites = useSelector((state) => state.favorites);

  const toggleDrawer = (newOpen) => () => {
    setDrawerOpen(newOpen);
  };

  const handleCurrencyClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCurrencyClose = (currencyCode) => {
    let currencySymbol;
    switch (currencyCode) {
      case "MAD":
        setCurrency("MAD");
        setCurrencyFlag(Morocco);
        currencySymbol = "MAD";
        break;
      case "EUR":
        setCurrency("€");
        setCurrencyFlag(France);
        currencySymbol = "EUR";
        break;
      case "USD":
        setCurrency("$");
        setCurrencyFlag(USA);
        currencySymbol = "USD";
        break;
      default:
        setCurrency("MAD");
        setCurrencyFlag(Morocco);
        currencySymbol = "MAD";
    }
    setAnchorEl(null);
    localStorage.setItem("selectedCurrency", currencySymbol);
  };

  const handleSearchClick = (event) => {
    setSearchAnchorEl(event.currentTarget);
  };

  const handleSearchClose = () => {
    setSearchAnchorEl(null);
  };

  const handleLogout = async () => {
    await userLogout();
    dispatch(checkAuthStatus());
    navigate("/");
  };

  return (
    <div className={classes.navbar_container}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container alignItems="center">
          <Grid item xs={4}>
            <div className={classes.navbar_left_elements_container}>
              <div className={classes.navbar_item} onClick={toggleDrawer(true)}>
                <img src={Bars} className={classes.navbar_icon} alt="Menu" />
                <span>MENU</span>
              </div>
              {false && (
                <div
                  className={`${classes.navbar_item} ${classes.magnifying}`}
                  onClick={handleSearchClick}
                >
                  <img
                    src={Magnifying}
                    className={classes.navbar_icon}
                    alt="Search"
                  />
                </div>
              )}
              {false && (
                <div
                  className={`${classes.navbar_item} ${classes.currency}`}
                  onClick={handleCurrencyClick}
                >
                  <img
                    src={currencyFlag}
                    className={classes.navbar_icon}
                    alt="Currency"
                  />
                  <span>{currency}</span>
                  <div className={classes.select_arrow_container}>
                    <img
                      src={SelectArrow}
                      className={classes.select_arrow_icon}
                      alt="Select Arrow"
                    />
                  </div>
                </div>
              )}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className={classes.navbar_img_container}>
              <Link to="/">
                <img
                  src={Logo}
                  className={classes.navbar_img}
                  alt="Les chandelles logo"
                />
              </Link>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className={classes.navbar_right_elements_container}>
            {false &&  <div
                className={`${classes.navbar_item} ${classes.magnifying}`}
                onClick={handleSearchClick}
              >
                <img
                  src={Magnifying}
                  className={classes.navbar_icon}
                  alt="Search"
                />
              </div>}
              <div className={classes.navbar_item}>
                <Link to={"/favorites"}>
                  <Badge
                    badgeContent={isLoggedIn ? favorites.length : 0}
                    classes={{ badge: classes.badge }}
                  >
                    <img
                      src={Heart}
                      className={classes.navbar_icon}
                      alt="Heart"
                    />
                  </Badge>
                </Link>
              </div>
              <div className={classes.navbar_item}>
                <Link to={"/cart"}>
                  <Badge
                    badgeContent={totalQuantity}
                    classes={{ badge: classes.badge }}
                  >
                    <img src={Bag} className={classes.navbar_icon} alt="Bag" />
                  </Badge>
                </Link>
              </div>
              <Link to={isLoggedIn ? "/profile" : "/login"}>
                <div className={classes.navbar_item}>
                  {isLoggedIn ? (
                    <img
                      src={User}
                      className={classes.navbar_icon}
                      alt="User"
                    />
                  ) : (
                    <img
                      src={Login}
                      className={classes.navbar_icon}
                      alt="Login"
                    />
                  )}
                </div>
              </Link>
              {isLoggedIn && (
                <div className={classes.navbar_item} onClick={handleLogout}>
                  <img
                    src={Logout}
                    className={classes.navbar_icon}
                    alt="Logout"
                  />
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </Box>

      <TemporaryDrawer open={drawerOpen} onClose={toggleDrawer(false)} />
      {false && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => handleCurrencyClose(currency)}
          PaperProps={{
            style: {
              width: "200px",
            },
          }}
        >
          <MenuItem onClick={() => handleCurrencyClose("EUR")}>€ Euro</MenuItem>
          <MenuItem onClick={() => handleCurrencyClose("USD")}>$ USD</MenuItem>
          <MenuItem onClick={() => handleCurrencyClose("MAD")}>
            MAD Dirham
          </MenuItem>
        </Menu>
      )}

      <Popover
        open={Boolean(searchAnchorEl)}
        anchorEl={searchAnchorEl}
        onClose={handleSearchClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {false && (
          <div style={{ padding: "10px", width: "100%" }}>
            <SearchBar />
          </div>
        )}
      </Popover>
    </div>
  );
}

export default Navbar;
