/**
 * Validates the provided phone number.
 * @param {string} phoneNumber - The phone number to validate.
 * @returns {string | null} - An error message if the phone number is invalid, otherwise `null`.
 */
export function validatePhoneNumber(phoneNumber) {
  if (!phoneNumber.trim()) {
    return "Le numéro de téléphone ne peut pas être vide.";
  }

  const phoneRegex = /^[0-9\s\-()+]+$/;
  if (!phoneRegex.test(phoneNumber)) {
    return "Le numéro de téléphone ne peut contenir que des chiffres, des espaces, des tirets, des parenthèses...";
  }

  const digitsOnly = phoneNumber.replace(/\D/g, "");
  if (digitsOnly.length < 10 || digitsOnly.length > 15) {
    return "Le numéro de téléphone doit contenir entre 10 et 15 chiffres.";
  }

  return null;
}
