import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import classes from "./SubscribeNewsletter.module.css";
import EastIcon from "@mui/icons-material/East";
import ScrollUp from "./../../ui/ScrollUp";
import { validateEmail } from "../../../utils/validation";
import { subscribeToNewsletter } from "../../../utils/http/newsletterService";
import { Alert } from "@mui/material";

function SubscribeNewsletter() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const mutation = useMutation({
    mutationFn: subscribeToNewsletter,
    onSuccess: () => {
      setSuccess("Abonnement à la newsletter réussi !");
      setEmail("");
      setError(null);
    },
    onError: (error) => {
      setSuccess(null);
      setError(error.message || "Une erreur est survenue.");
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    const emailError = validateEmail(email);
    if (emailError) {
      setError(emailError);
      return;
    }

    mutation.mutate(email);
  };

  return (
    <div className={classes.newsletter_container}>
      <div className={classes.alerts}>
    
        {error && (
          <Alert severity="error" className={classes.alert}>
            {error}
          </Alert>
        )}
        {success && (
          <Alert severity="success" className={classes.alert}>
            {success}
          </Alert>
        )}
      </div>
    
      <h1 className={classes.title}>Abonnez-vous à notre Newsletter</h1>
      <form onSubmit={handleSubmit}>
        <div className={classes.input_container}>
          <input
            className={`${classes.input} ${error ? classes.is_invalid : ""}`}
            type="email"
            name="email"
            placeholder="Entrez votre email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button
            className={classes.input_icon}
            type="submit"
            disabled={mutation.isLoading}
          >
            <EastIcon />
          </button>
        </div>
      </form>
      {window.innerWidth >= 768 && <ScrollUp />}
    </div>
  );
}

export default SubscribeNewsletter;
