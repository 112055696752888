import React from "react";
import ContactForm from "./ContactForm";
import ContactInfo from "./ContactInfo";
import ContactImg from "./ContactImg";
import classes from "./ContactUsContainer.module.css";
import ScrollUp from "./../ui/ScrollUp";
import { useMediaQuery, useTheme } from "@mui/material";
import Title from "../ui/Title";

function ContactUsContainer() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      {!isSmallScreen ? (
        <section>
          <div className={classes.contact_us_top_container}>
            <div className={classes.left_section}>
              <h1 className={classes.contact_us_title}>Parlez-nous de vous.</h1>
              <ContactForm />
              <ContactInfo />
            </div>
            <div className={classes.right_section}>
              <ContactImg />
            </div>
          </div>
          <div className={classes.contact_us_bottom_container}>
            <div className={classes.left_bottom_section}>
              <h1 className={classes.slogan}>
                Crafting light, Sharing delight
              </h1>
              <div className={classes.circle1}></div>
              <div className={classes.circle2}></div>
              <div className={classes.circle3}></div>
              <div className={classes.circle4}></div>
            </div>
            <div className={classes.right_bottom_section}>
              <div style={{ width: "100%" }}>
              <iframe
                  allowFullScreen
                  className={classes.map_part}
                  width="100%"
                  height="300"         
                  src="https://maps.google.com/maps?width=100%&amp;height=100%&amp;hl=en&amp;q=morocco, Oujda, les chandelles&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                  title="les chandelles location"
                  style={{ border: 'none' }}
                ></iframe>
              </div>
            </div>
          </div>
          <div style={{ textAlign: "center", margin: "70px" }}>
            <ScrollUp />
          </div>
        </section>
      ) : (
        <section>
          <Title title="contactez nous" />
          <ContactImg />
          <h1 className={classes.contact_us_title}>Parlez-nous de vous.</h1>
          <ContactForm />
          <ContactInfo />
        </section>
      )}
    </>
  );
}

export default ContactUsContainer;
