import React from "react";
import NewArrivals from "../components/new/NewArrivals";
import SubscribeNewsletter from './../components/common/newsletter/SubscribeNewsletter';

function NewArrivalsPage() {
  return (
    <>
      <NewArrivals />
      <SubscribeNewsletter />
    </>
  );
}

export default NewArrivalsPage;
