import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addFavoriteAsync,
  removeFavoriteAsync,
} from "../../redux/slices/favoritesSlice";
import classes from "./FavoriteMobileProduct.module.css";
import ColoredCircle from "../ui/ColoredCircle";

function FavoriteMobileProduct({
  imgSrc,
  productName,
  productPrice,
  productDescription,
  productColors = [],
  productId,
}) {
  const dispatch = useDispatch();
  const favorites = useSelector((state) => state.favorites);
  const isFavorite = favorites.some((item) => Number(item.product_id) === Number(productId));

  const handleFavoriteToggle = () => {
    if (isFavorite) {
      dispatch(removeFavoriteAsync(productId));
    } else {
      dispatch(addFavoriteAsync(productId));
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.imageContainer}>
        <img src={imgSrc} alt={productName} className={classes.product_image} />
      </div>
      <div className={classes.info_container}>
        <h2 className={classes.product_name}>{productName}</h2>
        <p className={classes.product_description}>{productDescription}</p>
        <div className={classes.colors_container}>
          {Array.isArray(productColors) && productColors.length > 0 ? (
            productColors.map((color, index) => (
              <ColoredCircle key={index} bgColor={color} />
            ))
          ) : (
            <p>Aucune couleur disponible</p>
          )}
        </div>
      </div>
      <div className={classes.product_price_container}>
        <p className={classes.product_price}>{productPrice}</p>
        <p className={classes.currency}>MAD</p>
        <button
          className={classes.favorite_button}
          onClick={handleFavoriteToggle}
        >
          {isFavorite ? "Retirer des favoris" : "Ajouter aux favoris"}
        </button>
      </div>
    </div>
  );
}

export default FavoriteMobileProduct;
