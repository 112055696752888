import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { checkAuth } from "../../utils/functions";
import { userLogout } from "../../utils/http/logoutService";
export const checkAuthStatus = createAsyncThunk(
  "auth/checkAuthStatus",
  async () => {
    const isAuthenticated = await checkAuth();
    return isAuthenticated;
  }
);

export const logoutAction = createAsyncThunk("auth/logoutAction", async () => {
  await userLogout();
  return false;
});

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isLoggedIn: false,
    loading: false,
    error: null,
  },
  reducers: {
    login(state) {
      state.isLoggedIn = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkAuthStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(checkAuthStatus.fulfilled, (state, action) => {
        state.isLoggedIn = action.payload;
        state.loading = false;
      })
      .addCase(checkAuthStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(logoutAction.fulfilled, (state) => {
        state.isLoggedIn = false;
      });
  },
});

export const { login } = authSlice.actions;
export default authSlice.reducer;
