import { React, useEffect } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { checkAuthStatus } from "./redux/slices/authSlice";
import { loadFavorites } from "./redux/slices/favoritesSlice";
import PublicLayout from "./layouts/PublicLayout";
import ErrorPage from "./pages/ErrorPage";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import ProductsPage from "./pages/ProductsPage";
import FrequentlyAskedQuestionsPage from "./pages/FrequentlyAskedQuestionsPage";
import NewArrivalsPage from "./pages/NewArrivalsPage";
import ProductDetailsPage from "./pages/ProductDetailsPage";
import FavoritesPage from "./pages/FavoritesPage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import CartPage from "./pages/CartPage";
import ProfilePage from "./pages/ProfilePage";
import GoogleCallback from "./components/google/GoogleCallback";
import { fetchCartAsync, syncLocalCartAsync } from "./redux/slices/cartSlice";

function App() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  useEffect(() => {
    const checkUserStatus = async () => {
      await dispatch(checkAuthStatus());
      if (isLoggedIn) {
        await dispatch(loadFavorites());
        await dispatch(syncLocalCartAsync());
        await dispatch(fetchCartAsync());
      }
    };

    checkUserStatus();
  }, [dispatch, isLoggedIn]);

  const queryClient = new QueryClient();
  const router = createBrowserRouter([
    {
      path: "/",
      element: <PublicLayout />,
      errorElement: <ErrorPage />,
      children: [
        { index: true, element: <HomePage /> },
        { path: "about", element: <AboutPage /> },
        { path: "contact", element: <ContactPage /> },
        { path: "faq", element: <FrequentlyAskedQuestionsPage /> },
        { path: "perfumes/:id", element: <ProductsPage /> },
        { path: "new-arrivals/:name", element: <NewArrivalsPage /> },
        { path: "product-details/:id", element: <ProductDetailsPage /> },
        { path: "favorites", element: <FavoritesPage /> },
        { path: "login", element: <LoginPage /> },
        { path: "register", element: <RegisterPage /> },
        { path: "forgot-password", element: <ForgotPasswordPage /> },
        { path: "reset-password", element: <ResetPasswordPage /> },
        { path: "cart", element: <CartPage /> },
        { path: "profile", element: <ProfilePage /> },
        { path: "auth/callback", element: <GoogleCallback /> },
      ],
    },
  ]);

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
}

export default App;
