import React, { useEffect } from "react";
import Products from "./../components/products/Products";
import BestSellers from "./../components/home/best-sellers/BestSellers";
import ProductsHeader from "./../components/products/ProductsHeader";

function ProductsPage() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <ProductsHeader />
      <Products />
      <BestSellers />
    </>
  );
}

export default ProductsPage;
