// import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import {
//   addToCart,
//   removeFromCart,
//   fetchCart,
//   addLocalStoreToCart,
// } from "../../utils/http/cartService";

// const getInitialState = () => {
//   const savedCart = localStorage.getItem("cart");
//   return savedCart
//     ? JSON.parse(savedCart)
//     : {
//         items: [],
//         totalQuantity: 0,
//         totalPrice: 0,
//         isLoading: false,
//         error: null,
//       };
// };

// const initialState = getInitialState();

// export const syncLocalCartAsync = createAsyncThunk(
//   "cart/syncLocalCart",
//   async (_, { getState, rejectWithValue }) => {
//     const state = getState();
//     const items = state.cart.items;
//     try {
//       if (items.length > 0) {
//         const response = await addLocalStoreToCart(items);
//         return response;
//       }
//       return null;
//     } catch (error) {
//       return rejectWithValue(error.message);
//     }
//   }
// );

// export const fetchCartAsync = createAsyncThunk(
//   "cart/fetchCart",
//   async (_, { rejectWithValue }) => {
//     try {
//       const response = await fetchCart();
//       return response;
//     } catch (error) {
//       return rejectWithValue(error.message);
//     }
//   }
// );

// export const addItemToCartAsync = createAsyncThunk(
//   "cart/addItemToCart",
//   async ({ newItem, isAuthenticated }, { rejectWithValue }) => {
//     try {
//       if (isAuthenticated) {
//         const response = await addToCart(newItem);
//         return { item: response, isAuthenticated };
//       } else {
//         return { item: newItem, isAuthenticated };
//       }
//     } catch (error) {
//       return rejectWithValue(error.message);
//     }
//   }
// );

// export const removeItemFromCartAsync = createAsyncThunk(
//   "cart/removeItemFromCart",
//   async ({ itemToRemove, isAuthenticated }, { rejectWithValue }) => {
//     try {
//       if (isAuthenticated) {
//         await removeFromCart(itemToRemove.id);
//       }
//       return { item: itemToRemove, isAuthenticated };
//     } catch (error) {
//       return rejectWithValue(error.message);
//     }
//   }
// );

// export const updateItemQuantityAsync = createAsyncThunk(
//   "cart/updateItemQuantity",
//   async ({ itemToUpdate, isAuthenticated }, { rejectWithValue }) => {
//     try {
//       if (isAuthenticated) {
//         const response = await addToCart(itemToUpdate);
//         return { item: response, isAuthenticated };
//       } else {
//         return { item: itemToUpdate, isAuthenticated };
//       }
//     } catch (error) {
//       return rejectWithValue(error.message);
//     }
//   }
// );

// const updateLocalStorage = (state) => {
//   localStorage.setItem(
//     "cart",
//     JSON.stringify({
//       items: state.items,
//       totalQuantity: state.totalQuantity,
//       totalPrice: state.totalPrice,
//     })
//   );
// };

// const getPriceBySize = (size, product) => {
//   if (!product) return 0;
//   if (size === "s") {
//     return Number(product.price_small) || 0;
//   } else if (size === "m") {
//     return Number(product.price_medium) || 0;
//   } else if (size === "l") {
//     return Number(product.price_large) || 0;
//   }
//   return 0;
// };

// const findItemIndex = (state, item, isAuthenticated) => {
//   if (isAuthenticated) {
//     return state.items.findIndex((stateItem) => stateItem.id === item.id);
//   } else {
//     return state.items.findIndex(
//       (stateItem) =>
//         stateItem.product_id === item.product_id &&
//         stateItem.color_id === item.color_id &&
//         stateItem.perfume_id === item.perfume_id &&
//         stateItem.size === item.size
//     );
//   }
// };

// const cartSlice = createSlice({
//   name: "cart",
//   initialState,
//   reducers: {
//     clearCart: (state) => {
//       state.items = [];
//       state.totalQuantity = 0;
//       state.totalPrice = 0;
//       localStorage.removeItem("cart");
//     },
//   },
//   extraReducers: (builder) => {
//     builder
//       .addCase(syncLocalCartAsync.fulfilled, (state, action) => {
//         if (action.payload) {
//           state.items = action.payload.items || [];
//           state.totalQuantity = action.payload.totalQuantity || 0;
//           state.totalPrice = action.payload.totalPrice || 0;
//         }
//       })
//       .addCase(fetchCartAsync.pending, (state) => {
//         state.isLoading = true;
//       })
//       .addCase(fetchCartAsync.fulfilled, (state, action) => {
//         state.items = action.payload.data || [];
//         state.totalQuantity = state.items.reduce(
//           (total, item) => total + (item.quantity || 0),
//           0
//         );
//         state.totalPrice = state.items.reduce(
//           (total, item) =>
//             total + (item.quantity || 0) * getPriceBySize(item.size, item),
//           0
//         );
//         state.isLoading = false;
//       })
//       .addCase(fetchCartAsync.rejected, (state, action) => {
//         state.isLoading = false;
//         state.error = action.payload;
//       })
//       .addCase(addItemToCartAsync.pending, (state) => {
//         state.isLoading = true;
//       })
//       .addCase(addItemToCartAsync.fulfilled, (state, action) => {
//         const { item: newItem, isAuthenticated } = action.payload;
//         const existingItemIndex = findItemIndex(
//           state,
//           newItem,
//           isAuthenticated
//         );

//         if (existingItemIndex !== -1) {
//           state.items[existingItemIndex].quantity += newItem.quantity;
//         } else {
//           state.items.push(newItem);
//         }

//         state.totalQuantity += newItem.quantity || 0;
//         state.totalPrice +=
//           getPriceBySize(newItem.size, newItem) * (newItem.quantity || 0);

//         if (!isAuthenticated) {
//           updateLocalStorage(state);
//         }

//         state.isLoading = false;
//       })
//       .addCase(addItemToCartAsync.rejected, (state, action) => {
//         state.isLoading = false;
//         state.error = action.payload;
//       })
//       .addCase(removeItemFromCartAsync.fulfilled, (state, action) => {
//         const { item: itemToRemove, isAuthenticated } = action.payload;
//         const existingItemIndex = findItemIndex(
//           state,
//           itemToRemove,
//           isAuthenticated
//         );

//         if (existingItemIndex !== -1) {
//           const existingItem = state.items[existingItemIndex];
//           state.totalPrice -=
//             getPriceBySize(existingItem.size, existingItem) *
//             (existingItem.quantity || 0);
//           state.totalQuantity -= existingItem.quantity || 0;
//           state.items.splice(existingItemIndex, 1);

//           if (!isAuthenticated) {
//             updateLocalStorage(state);
//           }
//         }
//       })
//       .addCase(updateItemQuantityAsync.fulfilled, (state, action) => {
//         const { item: updatedItem, isAuthenticated } = action.payload;
//         const existingItemIndex = findItemIndex(
//           state,
//           updatedItem,
//           isAuthenticated
//         );

//         if (existingItemIndex !== -1) {
//           const existingItem = state.items[existingItemIndex];
//           const oldQuantity = existingItem.quantity || 0;
//           const newQuantity = updatedItem.quantity || 0;
//           const priceDifference =
//             (newQuantity - oldQuantity) *
//             getPriceBySize(existingItem.size, existingItem);

//           existingItem.quantity = newQuantity;
//           state.totalQuantity += newQuantity - oldQuantity;
//           state.totalPrice += priceDifference;

//           if (!isAuthenticated) {
//             updateLocalStorage(state);
//           }
//         }
//       });
//   },
// });

// export const { clearCart } = cartSlice.actions;

// export default cartSlice.reducer;


import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addToCart,
  removeFromCart,
  fetchCart,
  addLocalStoreToCart,
} from "../../utils/http/cartService";
import { fetchProduct } from "../../utils/http/productService"; // Assurez-vous que ce chemin est correct

const getInitialState = () => {
  const savedCart = localStorage.getItem("cart");
  return savedCart
    ? JSON.parse(savedCart)
    : {
        items: [],
        totalQuantity: 0,
        totalPrice: 0,
        isLoading: false,
        error: null,
      };
};

const initialState = getInitialState();

export const syncLocalCartAsync = createAsyncThunk(
  "cart/syncLocalCart",
  async (_, { getState, rejectWithValue }) => {
    const state = getState();
    const items = state.cart.items;
    try {
      if (items.length > 0) {
        const response = await addLocalStoreToCart(items);
        return response;
      }
      return null;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchCartAsync = createAsyncThunk(
  "cart/fetchCart",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await fetchCart();
      const itemsWithDetails = await Promise.all(
        response.data.map(async (item) => {
          const productDetails = await dispatch(fetchProductDetails(item.product_id)).unwrap();
          return { ...item, productDetails };
        })
      );
      return { ...response, data: itemsWithDetails };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchProductDetails = createAsyncThunk(
  "cart/fetchProductDetails",
  async (productId, { rejectWithValue }) => {
    try {
      const response = await fetchProduct(productId);
      return response.product;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addItemToCartAsync = createAsyncThunk(
  "cart/addItemToCart",
  async ({ newItem, isAuthenticated }, { dispatch, rejectWithValue }) => {
    try {
      let itemWithDetails = newItem;
      if (isAuthenticated) {
        const response = await addToCart(newItem);
        itemWithDetails = response;
      }
      const productDetails = await dispatch(fetchProductDetails(newItem.product_id)).unwrap();
      return { item: { ...itemWithDetails, productDetails }, isAuthenticated };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const removeItemFromCartAsync = createAsyncThunk(
  "cart/removeItemFromCart",
  async ({ itemToRemove, isAuthenticated }, { rejectWithValue }) => {
    try {
      if (isAuthenticated) {
        await removeFromCart(itemToRemove.id);
      }
      return { item: itemToRemove, isAuthenticated };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateItemQuantityAsync = createAsyncThunk(
  "cart/updateItemQuantity",
  async ({ itemToUpdate, isAuthenticated }, { dispatch, rejectWithValue }) => {
    try {
      let updatedItem = itemToUpdate;
      if (isAuthenticated) {
        const response = await addToCart(itemToUpdate);
        updatedItem = response;
      }
      const productDetails = await dispatch(fetchProductDetails(itemToUpdate.product_id)).unwrap();
      return { item: { ...updatedItem, productDetails }, isAuthenticated };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const updateLocalStorage = (state) => {
  localStorage.setItem(
    "cart",
    JSON.stringify({
      items: state.items,
      totalQuantity: state.totalQuantity,
      totalPrice: state.totalPrice,
    })
  );
};

const getPriceBySize = (size, product) => {
  if (!product) return 0;
  let price = 0;
  if (size === "s") {
    price = Number(product.price_small) || 0;
  } else if (size === "m") {
    price = Number(product.price_medium) || 0;
  } else if (size === "l") {
    price = Number(product.price_large) || 0;
  }
  return price;
};

const findItemIndex = (state, item) => {
  return state.items.findIndex(
    (stateItem) =>
      stateItem.product_id === item.product_id &&
      stateItem.color_id === item.color_id &&
      stateItem.perfume_id === item.perfume_id &&
      stateItem.size === item.size
  );
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    clearCart: (state) => {
      state.items = [];
      state.totalQuantity = 0;
      state.totalPrice = 0;
      localStorage.removeItem("cart");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(syncLocalCartAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.items = action.payload.items || [];
          state.totalQuantity = action.payload.totalQuantity || 0;
          state.totalPrice = action.payload.totalPrice || 0;
        }
      })
      .addCase(fetchCartAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCartAsync.fulfilled, (state, action) => {
        state.items = action.payload.data || [];
        state.totalQuantity = state.items.reduce(
          (total, item) => total + (item.quantity || 0),
          0
        );
        state.totalPrice = state.items.reduce(
          (total, item) =>
            total + (item.quantity || 0) * getPriceBySize(item.size, item.productDetails),
          0
        );
        state.isLoading = false;
      })
      .addCase(fetchCartAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(addItemToCartAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addItemToCartAsync.fulfilled, (state, action) => {
        const { item: newItem, isAuthenticated } = action.payload;
        const existingItemIndex = findItemIndex(state, newItem);

        if (existingItemIndex !== -1) {
          state.items[existingItemIndex].quantity += newItem.quantity;
          state.items[existingItemIndex].productDetails = newItem.productDetails;
        } else {
          state.items.push(newItem);
        }

        state.totalQuantity += newItem.quantity || 0;
        state.totalPrice +=
          getPriceBySize(newItem.size, newItem.productDetails) * (newItem.quantity || 0);

        if (!isAuthenticated) {
          updateLocalStorage(state);
        }

        state.isLoading = false;
      })
      .addCase(addItemToCartAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(removeItemFromCartAsync.fulfilled, (state, action) => {
        const { item: itemToRemove, isAuthenticated } = action.payload;
        const existingItemIndex = findItemIndex(state, itemToRemove);

        if (existingItemIndex !== -1) {
          const existingItem = state.items[existingItemIndex];
          state.totalPrice -=
            getPriceBySize(existingItem.size, existingItem.productDetails) *
            (existingItem.quantity || 0);
          state.totalQuantity -= existingItem.quantity || 0;
          state.items.splice(existingItemIndex, 1);

          if (!isAuthenticated) {
            updateLocalStorage(state);
          }
        }
      })
      .addCase(updateItemQuantityAsync.fulfilled, (state, action) => {
        const { item: updatedItem, isAuthenticated } = action.payload;
        const existingItemIndex = findItemIndex(state, updatedItem);

        if (existingItemIndex !== -1) {
          const existingItem = state.items[existingItemIndex];
          const oldQuantity = existingItem.quantity || 0;
          const newQuantity = updatedItem.quantity || 0;
          const priceDifference =
            (newQuantity - oldQuantity) *
            getPriceBySize(existingItem.size, updatedItem.productDetails);

          existingItem.quantity = newQuantity;
          existingItem.productDetails = updatedItem.productDetails;
          state.totalQuantity += newQuantity - oldQuantity;
          state.totalPrice += priceDifference;

          if (!isAuthenticated) {
            updateLocalStorage(state);
          }
        }
      })
      .addCase(fetchProductDetails.fulfilled, (state, action) => {
        const updatedProduct = action.payload;
        const itemIndex = state.items.findIndex(item => item.product_id === updatedProduct.id);
        if (itemIndex !== -1) {
          state.items[itemIndex].productDetails = updatedProduct;
          state.totalPrice = state.items.reduce(
            (total, item) =>
              total + (item.quantity || 0) * getPriceBySize(item.size, item.productDetails),
            0
          );
        }
      });
  },
});

export const { clearCart } = cartSlice.actions;

export default cartSlice.reducer;