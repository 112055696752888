/**
 * Validates the provided password.
 * @param {string} password - The password to validate.
 * @returns {string | null} - An error message if the password is invalid, otherwise `null`.
 */

export function validatePassword(password) {
  if (!password.trim()) {
    return "Le mot de passe ne peut pas être vide.";
  }

  if (password.length < 8) {
    return "Le mot de passe doit contenir au moins 8 caractères.";
  }

  if (!/[A-Z]/.test(password)) {
    return "Le mot de passe doit contenir au moins une lettre majuscule.";
  }

  if (!/[a-z]/.test(password)) {
    return "Le mot de passe doit contenir au moins une lettre minuscule.";
  }

  if (!/\d/.test(password)) {
    return "Le mot de passe doit contenir au moins un chiffre.";
  }

  const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/;
  if (!specialCharacterRegex.test(password)) {
    return "Le mot de passe doit contenir au moins un caractère spécial.";
  }

  return null;
}
