import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { Box, Alert, CircularProgress } from "@mui/material";
import FilterMenu from "../ui/FilterMenu";
import { fetchProducts } from "../../utils/http/productService";
import classes from "./CartItems.module.css";
import CartItem from "./CartItem";
import {
  removeItemFromCartAsync,
  updateItemQuantityAsync,
  fetchCartAsync,
} from "../../redux/slices/cartSlice";

function CartItems() {
  const cartItems = useSelector((state) => state.cart.items);
  const isAuthenticated = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();

  const {
    data: productsData,
    isLoading: isProductsLoading,
    isError: isProductsError,
    error: productsError,
  } = useQuery({
    queryKey: ["products"],
    queryFn: fetchProducts,
    staleTime: 5000,
    select: (data) => data.products || [],
  });

  const [selectedPerfume, setSelectedPerfume] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);

  const handleRemoveItem = (cartItemId) => {
    dispatch(
      removeItemFromCartAsync({
        itemToRemove: { id: cartItemId },
        isAuthenticated,
      })
    );
    dispatch(fetchCartAsync());
  };

  const handleQuantityChange = (
    cartItemId,
    product_id,
    perfume_id,
    size,
    color_id,
    newQuantity
  ) => {
    if (newQuantity >= 0) {
      dispatch(
        updateItemQuantityAsync({
          itemToUpdate: {
            id: cartItemId,
            product_id,
            perfume_id,
            size,
            color_id,
            quantity: newQuantity,
          },
          isAuthenticated,
        })
      );
      dispatch(fetchCartAsync());
    }
  };

  const mergedCartItems = cartItems.map((cartItem) => {
    const product = productsData?.find(
      (product) => product.id === cartItem.product_id
    );

    const colorDetails = product?.colors.find(
      (color) => color.id === cartItem.color_id
    );

    return {
      ...cartItem,
      ...product,
      colorName: colorDetails ? colorDetails.name : "Couleur inconnue",
      perfume_id: product?.perfumes[0]?.id || null,
      cart_id: cartItem.id || null,
    };
  });

  const uniquePerfumes = [
    ...new Set(mergedCartItems.map((item) => item.perfumes?.[0]?.name)),
  ];
  const uniquePrices = [
    ...new Set(mergedCartItems.map((item) => item.price_small)),
  ];

  const perfumesContent = uniquePerfumes.length > 0 && (
    <FilterMenu
      label="Parfum"
      items={uniquePerfumes}
      selectedItem={selectedPerfume}
      onSelect={setSelectedPerfume}
      borderRight
    />
  );

  const pricesContent = uniquePrices.length > 0 && (
    <FilterMenu
      label="Prix"
      items={uniquePrices.map((price) => `$${price}`)}
      selectedItem={selectedPrice !== null ? `$${selectedPrice}` : "Prix"}
      onSelect={(price) => setSelectedPrice(parseFloat(price.replace("$", "")))}
      borderLeft
    />
  );

  if (cartItems.length === 0) {
    return (
      <div className={classes.cart_items_container}>
        <h1 className={classes.cart_items_title}>Détails Panier</h1>
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Alert severity="info">Votre panier est vide</Alert>
        </Box>
      </div>
    );
  }

  if (isProductsLoading) {
    return (
      <div className={classes.cart_items_container}>
        <h1 className={classes.cart_items_title}>Détails Panier</h1>
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      </div>
    );
  }

  if (isProductsError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="30vh"
      >
        <Alert severity="error" style={{ minWidth: "50vw" }}>
          {productsError?.message ||
            "Erreur lors de la récupération des données."}
        </Alert>
      </Box>
    );
  }

  return (
    <div className={classes.cart_items_container}>
      <h1 className={classes.cart_items_title}>Détails Panier</h1>
      <div className={classes.filter_container}>
        {perfumesContent}
        {pricesContent}
      </div>

      <div className={classes.cart_items}>
        {mergedCartItems.map((item) => (
          <CartItem
            key={item.cart_id}
            image={item.images?.[0]?.image_url || ""}
            name={item.name}
            perfume={item.perfumes?.[0]?.name || ""}
            description={item.description}
            color={item.colorName}
            size={item.size}
            quantity={item.quantity}
            price={
              item.size === "s"
                ? Number(item.price_small)
                : item.size === "m"
                ? Number(item.price_medium)
                : Number(item.large)
            }
            onRemove={() =>
              isAuthenticated
                ? handleRemoveItem(item.cart_id)
                : handleRemoveItem(
                    item.product_id,
                    item.perfume_id,
                    item.size,
                    item.color_id
                  )
            }
            onQuantityChange={(newQuantity) =>
              handleQuantityChange(
                item.cart_id,
                item.product_id,
                item.perfume_id,
                item.size,
                item.color_id,
                newQuantity
              )
            }
          />
        ))}
      </div>
    </div>
  );
}

export default CartItems;
