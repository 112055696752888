import React, { useState } from "react";
import styles from "./CartItem.module.css";
import Trash from "./../../assets/icons/trash.svg";

function CartItem({
  image,
  name,
  perfume,
  color,
  size,
  quantity,
  price,
  onRemove,
  onQuantityChange,
}) {
  const [itemQuantity, setItemQuantity] = useState(quantity);

  const handleQuantityChange = (e) => {
    const newQuantity = Number(e.target.value);
    if (newQuantity > 0) {
      setItemQuantity(newQuantity);
      onQuantityChange(newQuantity);
    }
  };

  return (
    <div className={styles.card_item_container}>
      <div className={styles.product_image}>
        <img src={image} alt="Product" />
      </div>
      <div className={styles.item_details}>
        <h4 className={styles.product_name}>{name}</h4>
        <div className={styles.product_details}>
          <div className={styles.product_size}>
            <p>
              Taille :
              {size === "s" ? "Petit" : size === "m" ? "Moyen" : "Grand"}
            </p>
          </div>
          <div className={styles.product_perfume}>
            <p>Purfum : {perfume}</p>
          </div>
          <div className={styles.product_color}>
            <p>Couleur : {color}</p>
          </div>
        </div>
      </div>
      <div className={styles.quantity_container}>
        <input
          type="number"
          value={itemQuantity}
          onChange={handleQuantityChange}
          className={styles.quantity_input}
          min="1"
          max="999"
        />
      </div>
      <div className={styles.price_container}>
        <p className={styles.price}>{parseFloat(price).toFixed(2)}DH</p>
      </div>
      <button className={styles.remove_button} onClick={onRemove}>
        <img src={Trash} alt="Remove" />
      </button>
    </div>
  );
}
export default CartItem;