import React from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import classes from "./Footer.module.css";
import Instagram from "./../../../assets/icons/Instagram.png";
import Facebook from "./../../../assets/icons/Facebook.png";
import Linkedin from "./../../../assets/icons/Linkedin.png";
import FacebookSvg from "./../../../assets/icons/facebook.svg";
import InstagramSvg from "./../../../assets/icons/instagram.svg";
import TikTok from "./../../../assets/icons/tiktok.svg";
import WhatsApp from "./../../../assets/icons/whatsapp.svg";
import ScrollUp from "../../ui/ScrollUp";

function Footer() {
  return (
    <footer className={classes.footer_container}>
      <Grid container spacing={3} className={classes.footer_content}>
        <Grid item xs={12} sm={4} className={classes.footer_sections}>
          <div className={classes.footer_section}>
            <Typography variant="h6" className={classes.footer_heading}>
              Aide
            </Typography>
            <Typography className={classes.footer_text}>
              Centre d'aide, Litiges et rapports, Protection des acheteurs,
              Signaler une violation des DPI, Informations réglementées,
              Conformité à l'intégrité
            </Typography>
          </div>
          <div style={{ marginTop: "1rem" }} className={classes.footer_section}>
            <Typography variant="h6" className={classes.footer_heading}>
              Parcourir par catégorie
            </Typography>
            <Typography className={classes.footer_text}>
              Tous les populaires, Produit, Promotion, Prix bas, Excellent
              rapport qualité-prix, Commentaires, Blog, Portail vendeur, BLACK
              FRIDAY, Assistant Les chandelles
            </Typography>
          </div>
        </Grid>

        <Grid item xs={12} sm={4} className={classes.footer_section}>
          <Typography variant="h6" className={classes.footer_heading}>
            Rester connecté
          </Typography>
          {window.innerWidth < 768 ? (
            <Box className={classes.social_links}>
              <Link
                to="https://facebook.com/people/les-chandelles/61553683991616/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={FacebookSvg}
                  alt="Facebook"
                  className={classes.social_link}
                />
              </Link>
              <Link
                to="https://www.instagram.com/les_chandelles_/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={InstagramSvg}
                  alt="Instagram"
                  className={classes.social_link}
                />
              </Link>
              <Link
                to="https://www.tiktok.com/@les_chandelles_"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={TikTok}
                  alt="TikTok"
                  className={classes.social_link}
                />
              </Link>
              <Link
                to="https://wa.me/+212637222323"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={WhatsApp}
                  alt="WhatsApp"
                  className={classes.social_link}
                />
              </Link>
            </Box>
          ) : (
            <Box className={classes.social_icons}>
              <Link
                to="https://www.instagram.com/les_chandelles_/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={Instagram}
                  alt="Instagram"
                  className={classes.social_icon}
                />
              </Link>
              <Link
                to="https://facebook.com/people/les-chandelles/61553683991616/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={Facebook}
                  alt="Facebook"
                  className={classes.social_icon}
                />
              </Link>
              <Link
                to="https://www.linkedin.com/company/leschandelles/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={Linkedin}
                  alt="LinkedIn"
                  className={classes.social_icon}
                />
              </Link>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} sm={4} className={classes.footer_section}>
          <Box className={classes.features}>
            <Typography variant="h6" className={classes.footer_heading}>
              Features
            </Typography>
            <Typography className={classes.feature}>Free shipping</Typography>
            <Typography className={classes.feature}>Free exchange</Typography>
            <Typography className={classes.feature}>
              Mode de paiement
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box className={classes.footer_copyright}>
        <div className={classes.scroll_up}>
          {window.innerWidth < 768 ? <ScrollUp /> : ""}
        </div>
        <Typography className={classes.copyright}>
          Tous les droits sont réservés pour SOFT CACTUS
        </Typography>
      </Box>
    </footer>
  );
}

export default Footer;
