import React, { useEffect, useState } from "react";
import { Grid, Alert } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { submiteUpdateProfile } from "../../utils/http/profileService";
import {
  validateName,
  validateEmail,
  validatePhoneNumber,
} from "../../utils/validation";
import classes from "./UserInfoForm.module.css";

function UserInfoForm({ user }) {
  const [successMessage, setSuccessMessage] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState(user?.email || "");
  const [phoneNumber, setPhoneNumber] = useState(user?.phone_number || "");
  const [birthDate, setBirthDate] = useState(user?.birth_date || "");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");

  useEffect(() => {
    if (user) {
      const [first, ...last] = user.name.split(" ");
      setFirstName(first);
      setLastName(last.join(" "));
    }
  }, [user]);

  const { mutate, isPending, isError, isSuccess, error } = useMutation({
    mutationFn: submiteUpdateProfile,
    onSuccess: (data) => {
      setSuccessMessage(data?.message || "Mot de passe changé avec succès.");
    },
    onError: (error) => {
      console.error("Login failed:", error.message);
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const firstNameValidationError = validateName(firstName);
    const lastNameValidationError = validateName(lastName);
    const emailValidationError = validateEmail(email);
    const phoneNumberValidationError = validatePhoneNumber(phoneNumber);

    if (firstNameValidationError) {
      setFirstNameError(firstNameValidationError);
    } else {
      setFirstNameError("");
    }

    if (lastNameValidationError) {
      setLastNameError(lastNameValidationError);
    } else {
      setLastNameError("");
    }

    if (emailValidationError) {
      setEmailError(emailValidationError);
    } else {
      setEmailError("");
    }

    if (phoneNumberValidationError) {
      setPhoneNumberError(phoneNumberValidationError);
    } else {
      setPhoneNumberError("");
    }
    if (
      !firstNameValidationError &&
      !lastNameValidationError &&
      !emailValidationError &&
      !phoneNumberValidationError
    ) {
      const formData = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone_number: phoneNumber,
        birth_date: birthDate,
      };
      mutate(formData);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={4} className={classes.user_form}>
        <Grid item xs={12}>
          {isError && (
            <Alert severity="error">
              {error.message || "Une erreur est survenue. Veuillez réessayer."}
            </Alert>
          )}
          {isSuccess && <Alert severity="success">{successMessage}</Alert>}
        </Grid>
        <Grid item xs={12} sm={6}>
          <label htmlFor="firstName">Prénom</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            className={firstNameError ? classes.input_error : classes.input}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder="Entrer votre prénom"
          />
          {firstNameError && (
            <span className={classes.error_message}>{firstNameError}</span>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <label htmlFor="lastName">Nom</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            className={lastNameError ? classes.input_error : classes.input}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder="Entrer votre nom"
          />
          {lastNameError && (
            <span className={classes.error_message}>{lastNameError}</span>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <label htmlFor="birthDate">Date de Naissance</label>
          <input
            type="date"
            id="birthDate"
            name="birthDate"
            className={classes.input}
            value={birthDate}
            onChange={(e) => setBirthDate(e.target.value)}
            placeholder="Entrer votre date de naissance"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <label htmlFor="phoneNumber">Numéro de Téléphone</label>
          <input
            type="tel"
            id="phoneNumber"
            name="phoneNumber"
            className={phoneNumberError ? classes.input_error : classes.input}
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="Entrer votre téléphone"
          />
          {phoneNumberError && (
            <span className={classes.error_message}>{phoneNumberError}</span>
          )}
        </Grid>
        <Grid item xs={12}>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            className={emailError ? classes.input_error : classes.input}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Entrer votre email"
          />
          {emailError && (
            <span className={classes.error_message}>{emailError}</span>
          )}
        </Grid>
        <Grid item xs={12}>
          <button className={classes.submit_btn} type="submit">
            {isPending ? "Sauvegarde en cours..." : "Sauvegarder"}
          </button>
        </Grid>
      </Grid>
    </form>
  );
}

export default UserInfoForm;
