import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import classes from "./ContactForm.module.css";
import {
  validateName,
  validateEmail,
  validatePhoneNumber,
} from "./../../utils/validation";
import { submitContactForm } from "../../utils/http/contactService";
import { Alert } from "@mui/material";

function ContactForm() {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    first_name: null,
    last_name: null,
    phone: null,
    email: null,
    message: null,
  });

  const { mutate, isPending, isError, isSuccess, error } = useMutation({
    mutationFn: submitContactForm,
    onError: (error) => {
      const backendErrors = error.response?.data?.errors || {};
      setErrors((prevErrors) => ({
        ...prevErrors,
        ...backendErrors,
      }));
      
    },
    onSuccess: () => {
      setFormData({
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        message: "",
      });
      setErrors({
        first_name: null,
        last_name: null,
        phone: null,
        email: null,
        message: null,
      });
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    switch (name) {
      case "first_name":
        setErrors((prevErrors) => ({
          ...prevErrors,
          first_name: validateName(value),
        }));
        break;
      case "last_name":
        setErrors((prevErrors) => ({
          ...prevErrors,
          last_name: validateName(value),
        }));
        break;
      case "phone":
        setErrors((prevErrors) => ({
          ...prevErrors,
          phone: validatePhoneNumber(value),
        }));
        break;
      case "email":
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: validateEmail(value),
        }));
        break;
      case "message":
        setErrors((prevErrors) => ({
          ...prevErrors,
          message: value.trim() ? null : "Le message ne peut pas être vide.",
        }));
        break;
      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {
      first_name: validateName(formData.first_name),
      last_name: validateName(formData.last_name),
      phone: validatePhoneNumber(formData.phone),
      email: validateEmail(formData.email),
      message: formData.message.trim()
        ? null
        : "Le message ne peut pas être vide.",
    };
    setErrors(newErrors);
    const isValid = Object.values(newErrors).every((error) => error === null);
    if (isValid) {
      mutate(formData);
    }
  };

  return (
    <section className={classes.contact_form_container}>
      <form onSubmit={handleSubmit} className={classes.card}>
        {isError && (
          <div className={classes.global_error}>
            {error.response?.data?.message || "Une erreur est survenue. Veuillez réessayer."}
          </div>
        )}
        {isSuccess && (
          <Alert severity="success" className={classes.success}> Message envoyé avec succès! </Alert>
        )}
        <div className={classes.row}>
          <div className={classes.input_group}>
            <input
              className={errors.first_name ? classes.is_invalid : ""}
              id="first_name"
              type="text"
              name="first_name"
              placeholder="Prénom*"
              value={formData.first_name}
              onChange={handleChange}
            />
            {errors.first_name && (
              <span className={classes.error}>{errors.first_name}</span>
            )}
          </div>
          <div className={classes.input_group}>
            <input
              className={errors.last_name ? classes.is_invalid : ""}
              id="last_name"
              type="text"
              name="last_name"
              placeholder="Nom*"
              value={formData.last_name}
              onChange={handleChange}
            />
            {errors.last_name && (
              <span className={classes.error}>{errors.last_name}</span>
            )}
          </div>
        </div>
        <div className={classes.input_group}>
          <input
            className={errors.phone ? classes.is_invalid : ""}
            id="phone"
            type="tel"
            name="phone"
            placeholder="Téléphone*"
            value={formData.phone}
            onChange={handleChange}
          />
          {errors.phone && (
            <span className={classes.error}>{errors.phone}</span>
          )}
        </div>
        <div className={classes.input_group}>
          <input
            className={errors.email ? classes.is_invalid : ""}
            id="email"
            type="email"
            name="email"
            placeholder="Email*"
            value={formData.email}
            onChange={handleChange}
          />
          {errors.email && (
            <span className={classes.error}>{errors.email}</span>
          )}
        </div>
        <div className={classes.input_group}>
          <textarea
            className={errors.message ? classes.is_invalid : ""}
            id="message"
            name="message"
            rows="5"
            cols="33"
            placeholder="Votre message*"
            value={formData.message}
            onChange={handleChange}
          ></textarea>
          {errors.message && (
            <span className={classes.error}>{errors.message}</span>
          )}
        </div>
        <div className={classes.input_group}>
          <button
            type="submit"
            className={classes.submit_button}
            disabled={isPending}
          >
            {isPending ? "Envoi en cours..." : "Envoyer"}
          </button>
        </div>
      </form>
    </section>
  );
}

export default ContactForm;
