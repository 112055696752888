import axios from "axios";
import { ApiRoutes } from "../../apis/ApiRoutes";

const apiClient = axios.create({
  baseURL: ApiRoutes.baseURL,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const submitLoginForm = async (formData) => {
  try {
    const response = await apiClient.post(ApiRoutes.login(), formData);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(
        error.response.data.message || "Erreur de soumission du formulaire"
      );
    } else if (error.request) {
      throw new Error("Aucune réponse du serveur");
    } else {
      throw new Error("Erreur dans la configuration de la requête");
    }
  }
};
