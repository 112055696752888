import React from "react";
import ArrowCircleUP from "./../../assets/icons/arrowCircleUP.svg";
import WhiteArrowUP from "./../../assets/icons/whiteArrowUp.svg";
import classes from "./ScrollUp.module.css";

function ScrollUp() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className={classes.scroll_up_conatainer}>
      {window.innerWidth >= 768 ? (
        <button
          onClick={scrollToTop}
          type="button"
          className={classes.desctop_scroll_up_btn}
        >
          <img src={ArrowCircleUP} alt="Scroll to top" />
        </button>
      ) : (
        <button
          onClick={scrollToTop}
          type="button"
          className={classes.mobile_scroll_up_btn}
        >
          <img src={WhiteArrowUP} alt="Scroll to top" />
        </button>
      )}
    </div>
  );
}

export default ScrollUp;
