import React from "react";
import { useMediaQuery } from "@mui/material";
import AuthImage from "../components/auth/AuthImage";
import ForgotPasswordForm from "../components/auth/ForgotPasswordForm";
import MobileForgotPasswordForm from "../components/auth/MobileForgotPasswordForm";

function ForgotPasswordPage() {
  const isMobile = useMediaQuery("(max-width:768px)");

  return (
    <>
      {!isMobile ? (
        <section style={{ display: "flex" }}>
          <AuthImage />
          <ForgotPasswordForm />
        </section>
      ) : (
        <MobileForgotPasswordForm open />
      )}
    </>
  );
}

export default ForgotPasswordPage;
